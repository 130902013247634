import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'lma-anomalies.wzf.ht-zustellung.count',
    label: 'Anzahl Sendungen mit Hinweis auf WZF bei Haustürzustellung',
    sumLabel: 'WZF HT Zustellung Hinweise',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
  })
);
