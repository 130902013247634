import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'lma-anomalies.hr.zustellzeit.count',
    label: 'Außerhalb Hermes Zustellzeit',
    sumLabel: 'Außerhalb Uhrzeit',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
  })
);
