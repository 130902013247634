import bruttoZustellzeitAvg from '@contexts/value-expression-context/value-expressions/brutto-zustellzeit-avg';
import Touren from '@contexts/value-expression-context/value-expressions/touren';
import HtProduktivitaet from '@contexts/value-expression-context/value-expressions/ht-produktivitaet';
import LademengeIstAvg from '@contexts/value-expression-context/value-expressions/lademenge-ist-avg';
import ZustellungenAvg from '@contexts/value-expression-context/value-expressions/zustellungen-avg';
import RuecklaufQuote from '@contexts/value-expression-context/value-expressions/ruecklauf-quote';
import ServicebeanstandungenGrundDashboard from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-grund-dashboard';
import LademengeSollAvg from '@contexts/value-expression-context/value-expressions/lademenge-soll-avg';
import RuecklauferAvg from '@contexts/value-expression-context/value-expressions/ruecklaufer-avg';
import Ruecklaufer from '@contexts/value-expression-context/value-expressions/ruecklaufer';
import ServicebeanstandungenMeldung from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-meldung';
import PrognoseSendungseingangsmenge from '@contexts/value-expression-context/value-expressions/prognose-sendungseingangsmenge';
import RuecklauferGrund from '@contexts/value-expression-context/value-expressions/ruecklaufer-grund';
import RuecklauferEilGrund from '@contexts/value-expression-context/value-expressions/ruecklaufer-eil-grund';

const data: any[] = [
  {
    label: 'Touren',
    showInSubmenu: true,
    tabMeta: {
      title: 'Touren',
      valueExpressionKey: Touren.identifier,
    },
  },

  {
    label: 'Rücklauf',
    showInSubmenu: true,
    showInOverview: true,
    tabMeta: {
      title: 'RL-Quote',
      valueExpressionKey: RuecklaufQuote.identifier,
    },
    components: [
      {
        component: 'DetailedChart',
        props: {
          uID: 'ruecklaufChartRuecklaufquote',
          valueExpressionKey: RuecklaufQuote.identifier,
          showChartModeSelector: false,
          enableKpiAvg: true,
          showDateSelector: true,
          defaultChartType: 'line',
          title: 'Rücklaufquote',
          renderChildren: false,
        },
      },
      {
        component: 'DetailedChart',
        props: {
          uID: 'ruecklaufChartRuecklaufgruende',
          showChartModeSelector: true,
          showDateSelector: true,
          defaultChartType: 'lineStacked',
          showToggle: true,
          enableLegend: true,
        },
      },
      {
        component: 'RuecklaufDataTable',
        props: {
          componentId: 'ruecklauf.table',
          availableValueExpressionKeys: [
            Touren.identifier,
            LademengeIstAvg.identifier,
            LademengeSollAvg.identifier,
            ZustellungenAvg.identifier,
            RuecklaufQuote.identifier,
            RuecklauferAvg.identifier,
            Ruecklaufer.identifier,
            ...RuecklauferGrund.getChildren().map((c) => c.identifier),
            ...RuecklauferEilGrund.getChildren().map((c) => c.identifier),
          ],
          defaultSorting: [
            {
              id: 'rl-quote',
              desc: true,
            },
          ],
          defaultValueExpressionKeys: [
            Touren.identifier,
            LademengeIstAvg.identifier,
            LademengeSollAvg.identifier,
            ZustellungenAvg.identifier,
            RuecklauferAvg.identifier,
            Ruecklaufer.identifier,
            RuecklaufQuote.identifier,
          ],
        },
      },
    ],
  },
  {
    label: 'Beanstandungen',
    showInSubmenu: true,
    showInOverview: true,
    permission: 'complaints',
    hideForOrgKeyPattern: [/^ov/],
    tabMeta: {
      title: 'Servicebeanstandungen',
      valueExpressionKey: ServicebeanstandungenMeldung.identifier,
    },
    components: [
      {
        component: 'ComplaintsChart',
        props: {
          uID: 'SbTopChart',
          title: 'Servicebeanstandungen nach Meldedatum',
          hideTodayInSelection: true,
        },
      },
      {
        component: 'DetailedChart',
        props: {
          uID: 'SbDetailsChart',
          valueExpressionKey: ServicebeanstandungenGrundDashboard.identifier,
          title: 'Servicebeanstandungsgründe nach Meldedatum',
          showChartModeSelector: true,
          threshold: 2,
          showDateSelector: true,
          hideTodayInSelection: true,
          enableLegend: true,
          renderChildren: true,
        },
      },
      {
        component: 'BeanstandungenDataTable',
        props: {
          componentId: 'beanstandungen.table',
          defaultSorting: [{ id: 'tourIdentifier', desc: true }],
        },
      },
    ],
  },
  {
    label: 'Hinweise',
    showInSubmenu: true,
    showInOverview: false,
    tabMeta: {
      title: 'Hinweise',
      valueExpressionKey: 'notification',
    },
    components: [],
  },
  {
    label: 'Prognose',
    showInSubmenu: true,
    showInOverview: false,
    tabMeta: {
      title: 'Mengenprognose',
      valueExpressionKey: PrognoseSendungseingangsmenge.identifier,
    },
    hideForOrgKeyPattern: [/^ov/],
    components: [
      {
        component: 'DetailedChart',
        props: {
          uID: 'mengenprognoseChartPrognose',
          valueExpressionKey: PrognoseSendungseingangsmenge.identifier,
          showChartModeSelector: false,
          showMetricValues: true,
          enableKpiAvg: false,
          showDateSelector: true,
          defaultChartType: 'bar',
          renderChildren: false,
        },
      },
      {
        component: 'CalendarTable',
        props: {
          uID: 'mengenprognoseCalendarPrognose',
          valueExpressionKey: PrognoseSendungseingangsmenge.identifier,
          fixedValueExpressionKey: 'fixed_mengenprognose',
          showTotals: true,
          showFixed: false,
        },
      },
    ],
  },
  {
    label: 'Kennzahlen',
    showInSubmenu: true,
    showInOverview: false,
    tabMeta: {
      title: 'Kennzahlen',
      valueExpressionKey: 'Kennzahlen',
    },
    components: [
      {
        component: 'KpiCompare',
        props: {
          uID: 'kpiCompare',
        },
      },
      {
        component: 'KennzahlenDataTable',
        props: {
          componentId: 'kennzahlen.table',
          defaultSorting: [{ id: Touren.identifier, desc: true }],
          defaultValueExpressionKeys: [Touren.identifier],
        },
      },
    ],
  },
  {
    label: 'Tourproduktivität',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'HT-Produktivität',
      valueExpressionKey: HtProduktivitaet.identifier,
    },
  },
  {
    label: 'Lademenge',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'Lademenge',
      valueExpressionKey: LademengeIstAvg.identifier,
    },
  },
  {
    label: 'Zustellungen',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'Zustellungen',
      valueExpressionKey: ZustellungenAvg.identifier,
    },
  },
  {
    label: 'Rücklauf',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'RL-Quote',
      valueExpressionKey: RuecklaufQuote.identifier,
    },
  },
  {
    label: 'Zustellzeit',
    showInSingleTourZeitverlauf: true,
    tabMeta: {
      title: 'Brutto-Zustellzeit',
      valueExpressionKey: bruttoZustellzeitAvg.identifier,
    },
  },
];

export default data;
