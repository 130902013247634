import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'Nachbarschaftsabgabe Quote',
  label: 'Nachbarschaftsabgabe Quote',
  definition: getDefinitionByName('Nachbarschaftsabgabe Quote'),
  positiveDirection: PositiveDirection.up,
  valueQuotient: 1 / 100,
  valueFormat: '-#.###.##0,#0%',
});

const expression = new ValueExpression(metric);

expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
