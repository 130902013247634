import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ServicebeanstandungenGrundDashboard from '@contexts/value-expression-context/value-expressions/servicebeanstandungen-grund-dashboard';
import {
  ValuableComplaintReasonsMap,
  ValuableComplaintsReasons,
} from '@legacy-modules/complaints/models/enums/ValuableComplaintsReason';

const metric = new MetricType({
  key: 'complaints.reported.valuable.count',
  label: 'vSB (Meldung)',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: 'down',
  category: 'complaints',
  description: '',
  definition: getDefinitionByName('vSB (Meldung)'),
});

const expression = new ValueExpression(metric);

expression.getChildren = () => {
  // filter verlustrelevant children
  return ServicebeanstandungenGrundDashboard.getChildren().filter((child) => {
    return Object.values(ValuableComplaintReasonsMap).includes(child.getLabel() as ValuableComplaintsReasons);
  });
};

export default expression;
