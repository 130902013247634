import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'lma-anomalies.usk.ruecklauf-am-tourende.count',
  label: 'Rücklauf am Tourende',
  sumLabel: 'RL am Tourende',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('RL am Tourende'),
  hidden: true,
});

const expression = new ValueExpression(metric);

// TODO: can we get rid of this?

export default expression;
