import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'Rücklaufquote',
  label: 'RL-Quote',
  definition: getDefinitionByName('Rücklaufquote'),
  valueQuotient: 0.01,
  valueFormat: '-#.###.##0,#0%',
});

const expression = new ValueExpression(metric);
expression.percent = true;

export default expression;
