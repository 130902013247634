import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

const label = 'GPS Verzögerung';
const key = 'finished_deliveries.stop_journey.location.time_lag';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category}`,
    label: `${label} (${category})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.down,
    category: 'tours',
    description: '<p>Zeitliche Abweichung der Koordinate zum Stop in Sekunden</p>\n',
  });
};

const sumType = getMetricType('sum');
sumType.label = label;
sumType.chart = ChartType.line;

const gpsLocationTimeLagSum = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
const categories = ['0-5', '6-10', '11-15', '16-20', '21-30', '31-60', '61-120', '121-300', '>300'];
gpsLocationTimeLagSum.getChildren = () => categories.map((category) => new ValueExpression(getMetricType(category)));

export default gpsLocationTimeLagSum;
