import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'zustellungen_pro_kilometer2',
  label: 'Zustellungen pro km²',
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0',
  aggregation: AggregationType.sum,
});

const expression = new ValueExpression(metric);

expression.targetValueType = TargetValueType.QUOTE;

export default expression;
