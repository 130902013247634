import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const expression = new ValueExpression(
  new MetricType({
    key: 'anfahrtsstrecke',
    label: 'Anfahrtsstrecke',
    valueFormat: '-#.###.##0,0 km',
    valueQuotient: 1000,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.down,
    category: 'tours',
    definition: getDefinitionByName('Anfahrtsstrecke'),
    hidden: true,
  })
);

expression.targetValueType = TargetValueType.SUM;

export default expression;
