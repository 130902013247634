import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Anzahl Stopps nach Nachweisart';
const key = 'finished_deliveries.proof_of_delivery.by_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${label} (${category.label})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.up,
    category: 'deliveries',
    description: '<p>Anzahl Stopps nach Nachweisart (Foto-Unterschrift, Unterschrift, Scanner, Ohne)</p>\n',
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;
sumType.chart = ChartType.line;

const gpsLocationAccuracySum = new ValueExpression(sumType);

const categories = {
  none: 'Ohne',
  image: 'Foto',
  signature: 'Unterschrift',
  imageSignature: 'Foto-Unterschrift',
  tan: 'Tan',
};

// hacky solution for "Übersichtsbereich"
gpsLocationAccuracySum.getChildren = () =>
  Object.entries(categories).map((category) => {
    const [key, label] = category;
    return new ValueExpression(getMetricType({ key: key, label: label }));
  });

export default gpsLocationAccuracySum;
