import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType, { complaintReasons } from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

/**
 * Diese KPI ist die "SB nach Grund (Meldung)"-Variante für das Dashboard.
 * Wir ersetzen beim Mappen des Metric-Typs hier das Label, anstatt es
 * zu konkatenieren.
 */

const label = 'SB nach Grund (Meldung)';
const key = 'complaints.reported.count_by_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${category.label}`, // Kurzes Label hier
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: 'down',
    category: 'complaints',
    description:
      '<p>Kundenkontakte im Customer Service, bei denen es sich um Servicebeanstandungen handelt nach Grund</p>\n',
    definition: getDefinitionByName('SB (Meldung)'),
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;
sumType.chart = ChartType.line;

const sumExpression = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
sumExpression.getChildren = () =>
  complaintReasons.map((reason) => {
    return new ValueExpression(getMetricType({ key: reason, label: reason }));
  });

export default sumExpression;
