import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const expression = new ValueExpression(
  new MetricType({
    key: 'tourfahrtzeit_anfahrt_avg',
    label: 'Ø Anfahrtszeit',
    valueFormat: '-#.###.##0,0 h',
    valueQuotient: 3600,
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.down,
    definition: getDefinitionByName('Anfahrtszeit'),
  })
);

expression.targetValueType = TargetValueType.QUOTE;

export default expression;
