import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

const metric = new MetricType({
  key: 'zsb_plus_0_quote',
  label: 'ZSB+0 Standard',
  definition: getDefinitionByName('ZSB+0 Standard'),
  positiveDirection: PositiveDirection.up,
  valueQuotient: 0.01,
  valueFormat: '-#.###.##0,#0%',
});

const expression = new ValueExpression(metric);

expression.percent = true;
expression.chartRenderOptions.startYAxisOnZero = false;

export default expression;
