import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const metric = new MetricType({
  key: 'lademenge_ist',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.custom,
  label: 'IST-Lademenge',
  definition: getDefinitionByName('IST-Lademenge'),
  chart: ChartType.bar,
});

export default new ValueExpression(metric);
