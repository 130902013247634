import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'boimport.schaden_verlust.recourses.all.count.total',
    label: 'Schaden/Verlust Regresse Anzahl',
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.down,
    category: 'tours',
    description: '',
    hidden: true,
  })
);
