import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

export default new ValueExpression(
  new MetricType({
    key: 'lma-shipmentbased.TA.relevant_tan.count.total',
    label: 'TAN Sendungen auf Tour',
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    definition: getDefinitionByName('TAN Sendungen auf Tour'),
  })
);
