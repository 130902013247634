import { OrgKey } from '@data-table/data-table.types';
import { injectContractorViewMode } from '@legacy-modules/dashboard/utils/OrgKeyUtils';
import { ContractorViewMode } from '@legacy-modules/navigation/constants/ContractorViewMode';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateFromRoutingParams } from './store';
import { AppRoutingParams } from '@legacy-modules/routing/models/AppRoutingParams';
import LademengeIst from '@contexts/value-expression-context/value-expressions/lademenge-ist';

export type ActiveDashboardView =
  | 'tour_loaded.list'
  | 'tour_loaded.count'
  | 'sb_all'
  | 'mengenprognose'
  | 'ruecklaufquote'
  | 'Kennzahlen'
  | 'notification';

export type DashboardState = {
  orgKey: OrgKey;
  contractorViewMode: ContractorViewMode;
  activeDashboardView?: ActiveDashboardView;
  barcode: string;
  dashboardTrendValueExpressionIdentifier: ValueExpression['identifier'];
  localComponentOptions: Record<string, Record<string, any>>;
};

const initialState: DashboardState = {
  orgKey: null,
  contractorViewMode: ContractorViewMode.Home,
  activeDashboardView: null,
  barcode: '',
  dashboardTrendValueExpressionIdentifier: LademengeIst.identifier,
  localComponentOptions: {},
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setOrgKey: (state, action: PayloadAction<string>) => {
      const orgKey = action.payload?.split(',')?.[0];
      if (ContractorUtils.isContractor(action.payload)) {
        state.contractorViewMode = ContractorViewMode.Home;
        state.orgKey = injectContractorViewMode(orgKey, ContractorViewMode.Home);
      } else {
        state.contractorViewMode = null;
        state.orgKey = orgKey;
      }
    },
    setContractorViewMode: (state, action: PayloadAction<ContractorViewMode>) => {
      const contractorViewMode = action.payload;
      state.contractorViewMode = contractorViewMode;
      state.orgKey = injectContractorViewMode(state.orgKey, contractorViewMode);
    },
    setActiveDashboardView: (state, action: PayloadAction<ActiveDashboardView>) => {
      state.activeDashboardView = action.payload;
    },
    setBarcode: (state, action: PayloadAction<string>) => {
      state.barcode = action.payload;
    },
    setLocalComponentOptions: (
      state,
      action: PayloadAction<{ componentId: string; optionKey: string; value: any }>
    ) => {
      state.localComponentOptions[action.payload.componentId] = {
        ...(state.localComponentOptions[action.payload.componentId] ?? {}),
        [action.payload.optionKey]: {
          ...(state.localComponentOptions[action.payload.componentId]?.[action.payload.optionKey] ?? {}),
          ...action.payload.value,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFromRoutingParams, (state, action: PayloadAction<AppRoutingParams>) => {
      state.activeDashboardView = action.payload.dashboard.activeDashboardView ?? null;
      state.contractorViewMode = action.payload.dashboard.contractorViewMode ?? initialState.contractorViewMode;
      state.orgKey = action.payload.dashboard.orgKey;
    });
  },
});
