import ValueExpression from '../valueexpressions/ValueExpression';
import MetricDataConnection from '../../../metrics2/models/dataconnection/MetricDataConnection';
import GroupedDataConnection from '../../../dataconnection/connections/GroupedDataConnection';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import { MetricDataConnectionOutputFormats } from '../../../metrics2/models/enumerations/MetricDataConnectionOutputFormat';
import MetricDataUtils from '../../../metrics2/converters/MetricDataUtils';
import { MetricsValue } from '../../../metrics2/models/entities/MetricsValue';

export default class ValueExpressionDataConnection extends GroupedDataConnection<any, any> {
  outputFormat: MetricDataConnectionOutputFormats = MetricDataConnectionOutputFormats.dateArray;
  sumValueKeys: boolean = false;
  valueExpression: ValueExpression;
  // @ts-ignore
  _dataConnections: {
    [key: string]: MetricDataConnection;
  };

  constructor(valueExpression: ValueExpression, metricDataConnections: Array<MetricDataConnection>) {
    // @ts-ignore
    super(metricDataConnections);
    this.valueExpression = valueExpression;
  }

  setOutputFormat(format: MetricDataConnectionOutputFormats) {
    this.outputFormat = format;
  }

  setSumValueKeys(active: boolean = true) {
    this.sumValueKeys = active;
  }

  get dataSingle(): MetricsValue | null | undefined {
    return MetricDataUtils.metricData2Single(this._getDataEntities(true), this.valueExpression.aggregation);
  }

  get dataDateArray(): Array<MetricsValue> {
    return MetricDataUtils.metricData2DateArray(this._getDataEntities(), this.valueExpression.aggregation);
  }

  get dataDateObject(): {
    [key: string]: MetricsValue;
  } {
    return MetricDataUtils.metricData2DateObject(this._getDataEntities());
  }

  get dataMappedDateArrays():
    | {
        [key: string]: Array<number>;
      }
    | Array<number> {
    return MetricDataUtils.metricData2MappedDateArrays(this._getDataEntities(), this.valueExpression.aggregation);
  }

  get dataEntities(): Map<ValueExpressionEntityKey, number> {
    return this._getDataEntities();
  }

  get dataOrgKeyObject(): {
    [key: string]: MetricsValue;
  } {
    return MetricDataUtils.metricData2OrgKeyObject(this._getDataEntities());
  }

  _getDataEntities(forceNoneGrouping: boolean = false): Map<ValueExpressionEntityKey, number> {
    const metricsData: Map<MetricsEntityKey, number> = new Map();
    Object.values(this._dataConnections).forEach((dc: MetricDataConnection) => {
      if (dc.dataEntities) {
        Array.from(dc.dataEntities.entries()).forEach(([k, v]) => {
          if (v != null) {
            metricsData.set(k, v);
          }
        });
      }
    });
    return this.valueExpression.processValues(metricsData);
  }

  get data(): Object {
    switch (this.outputFormat) {
      case MetricDataConnectionOutputFormats.single:
        return this.dataSingle;
      case MetricDataConnectionOutputFormats.dateObject:
        return this.dataDateObject;
      case MetricDataConnectionOutputFormats.mappedDateArrays:
        return this.dataMappedDateArrays;
      case MetricDataConnectionOutputFormats.entities:
        return this.dataEntities;
      case MetricDataConnectionOutputFormats.orgKeyObject:
        return this.dataOrgKeyObject;
      case MetricDataConnectionOutputFormats.dateArray:
      default:
        return this.dataDateArray;
    }
  }
}
