import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KpiDetailsContainer from './KpiDetailsContainer';
import KpiOverview from './KpiOverview';
import DashboardTrend, { DASHBOARD_TREND_UID } from './DashboardTrend';
import Styles from './DashboardContainer.module.scss';
import { MdSettings } from 'react-icons/md';
import { PopOver } from '../../common/components/PopOver';
import { ComponentSortItem, DashboardComponentSortPopover } from './DashboardComponentSortPopover';
import { ConfigurationKey } from '../websocket/ConfigPayload';
import { calculateYesterday, Duration, Durations } from '../models/enums/Duration';
import { Loader } from '../../common/components/Loader';
import classNames from 'classnames';
import { ChartType } from '../../metrics2/models/enumerations/ChartType';
import { DashboardName } from '../constants/DashboardName';
import { AnomalyPage } from './AnomalyPage';
import DataTable from '@data-table';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import { useUserConfig } from '@hooks/use-user-config-hook';
import { selectActiveDashboardView, selectDashboardTrendValueExpression } from '@redux/dashboard.selectors';
import { dashboardSlice } from '@redux/dashboard.slice';

const defaultValueExpressionKeys = ['tour_loaded.count', 'ist_lademenge/touren', 'ruecklaufquote'];

type Props = {};

type TrendConfig = {
  duration: Omit<Duration, 'isVisibleInForecast' | 'from' | 'to'>;
  valueExpressionKey: string;
};

enum ComponentKey {
  Overview = 'overview',
  Trend = 'trend',
  Table = 'table',
}

const itemConfiguration: {
  [k in ComponentKey]: {
    name: string;
    disabled: boolean;
  };
} = {
  [ComponentKey.Overview]: {
    name: 'Übersicht',
    disabled: false,
  },
  [ComponentKey.Trend]: {
    name: 'Trend',
    disabled: false,
  },
  [ComponentKey.Table]: {
    name: 'Tabelle',
    disabled: false,
  },
};

const initItems = {
  order: [
    {
      key: 'overview',
      active: true,
    },
    {
      key: 'trend',
      active: true,
    },
    {
      key: 'table',
      name: 'Tabelle',
      disabled: false,
      active: true,
    },
  ],
};

const DashboardContainer: React.FC<Props> = () => {
  const targetRef = useRef(null);
  const dispatch = useDispatch();
  const activeDashboard = useSelector(selectActiveDashboardView);
  const valueExpression = useSelector(selectDashboardTrendValueExpression);

  const valueExpressionMap = useValueExpressionContext();

  const [config, updateConfig, configLoading] = useUserConfig<typeof initItems>(
    ConfigurationKey.DashboardComponents,
    initItems
  );

  const defaultTrendConfig: TrendConfig = useMemo(
    () => ({
      valueExpressionKey: valueExpression?.identifier,
      duration: Durations.last_7,
    }),
    [valueExpression.identifier]
  );

  const [trendConfig, updateTrendConfig, trendConfigLoading] = useUserConfig<TrendConfig>(
    ConfigurationKey.DashboardChart,
    defaultTrendConfig
  );

  const setTrendComponentDate = useCallback(
    (duration: Duration) => {
      updateTrendConfig({
        ...trendConfig,
        duration,
      });
    },
    [trendConfig, updateTrendConfig]
  );
  const setTrendKpiValue = useCallback(
    (valueExpressionKey: string) => {
      const valueExpression = valueExpressionMap.get(valueExpressionKey);
      if (!valueExpression) {
        throw new Error(`Value expression not found for key: ${valueExpressionKey}`);
      }

      updateTrendConfig({
        ...trendConfig,
        valueExpressionKey,
      });
      dispatch(
        dashboardSlice.actions.setLocalComponentOptions({
          componentId: DASHBOARD_TREND_UID,
          optionKey: 'selectedChartType',
          value: valueExpression.hasChildren() ? ChartType.bar : ChartType.line,
        })
      );
    },
    [dispatch, trendConfig, valueExpressionMap, updateTrendConfig]
  );

  const settingsRef = useRef<HTMLDivElement | null>(null);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const sortConfiguration: ComponentSortItem[] = useMemo(
    () =>
      (config?.order ?? initItems?.order)?.map((c) => ({
        ...c,
        ...itemConfiguration[c.key],
      })),
    [config]
  );

  const components = sortConfiguration.map((item) => {
    const { key, active } = item;

    if (!active) {
      return null;
    }

    switch (key) {
      case 'overview':
        return <KpiOverview key={key} />;
      case 'trend':
        return trendConfigLoading ? (
          <Loader key={key} />
        ) : (
          <DashboardTrend
            key={key}
            onKpiSelectionChange={setTrendKpiValue}
            onDurationSelected={setTrendComponentDate}
            duration={calculateYesterday(trendConfig?.duration || Durations.last_7)}
            selectedKpiKey={trendConfig?.valueExpressionKey}
          />
        );
      case 'table':
        return (
          <DataTable.DashboardDataTable
            key={key}
            componentId='dashboard.table'
            defaultValueExpressionKeys={defaultValueExpressionKeys}
          />
        );
      default:
        return null;
    }
  });

  const onResortComponent = useCallback(
    (items) => {
      setSettingsOpen(false);
      updateConfig({
        order: items.map((i) => ({
          active: i.active,
          key: i.key,
        })),
      });
    },
    [updateConfig]
  );

  useEffect(() => {
    if (!targetRef?.current) {
      return;
    }
    targetRef.current.scrollTo(0, 0);
  }, [targetRef]);

  if (configLoading) return null;
  return (
    <div ref={targetRef} className={Styles.Container}>
      <PopOver
        placement={'bottom-end'}
        visible={settingsOpen}
        anchorElement={settingsRef?.current}
        closeOnClickOutside
        closeOnEscape
        onClose={() => setSettingsOpen(false)}>
        <DashboardComponentSortPopover onConfirm={onResortComponent} items={sortConfiguration} />
      </PopOver>
      {activeDashboard !== null && <KpiDetailsContainer />}
      {activeDashboard === null && components}
      {activeDashboard === DashboardName.Notification && <AnomalyPage />}
      {activeDashboard === null && (
        <div className={classNames(Styles.Settings)} ref={settingsRef} onClick={() => setSettingsOpen((v) => !v)}>
          <MdSettings />
        </div>
      )}
    </div>
  );
};

export default DashboardContainer;
