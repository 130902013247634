import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'lademenge_soll',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    label: 'SOLL-Lademenge',
    definition: getDefinitionByName('SOLL-Lademenge'),
    chart: ChartType.bar,
  })
);
