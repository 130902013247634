import { useMemo } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import {
  AggregationDateFilterInput,
  DateRangeAggregationInput,
  DateRangeGrouping,
  KpisQuery,
  KpisQueryVariables,
  useKpisQuery,
  WeekdayInput,
} from '@graphql-client/graphql';
import { DateRange, DateRange as LegacyDateRange } from '@legacy-modules/utils/dates/DateRange';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { selectToken } from '@redux/auth.selectors';
import { DateRangeGrouping as LegacyDateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import { WeekResolution as LegacyWeekResolution } from '@legacy-modules/dashboard/utils/ChartUtils';
import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export type TransformedKpis = {
  kpis: {
    groups: Array<{
      group: string;
      orgKey: any;
      dateRange: { from: any; until: any };
      kpiValues: Array<{ kpiId: string; value: number }>;
    }>;
    summary: {
      kpiValues: Array<{ kpiId: string; value: number }>;
    };
  };
};

let requestId = null;
export const generateRequestId = () => {
  if (requestId === null) {
    requestId = crypto.randomUUID();
  }
  return requestId;
};

/** Iterate through kpi values of results to filter out 'NA' flagged entries */
export const NotAvailableFilter = (kpiValue: { kpiId: string; value: string }) => kpiValue.value !== 'NA';
/** Parse as float, since we use numbers throughout the app */
export const ParseFloatMapper = (kpiValue: { kpiId: string; value: string }) => ({
  ...kpiValue,
  value: parseFloat(kpiValue.value),
});
/** Take the value expression value and divide it by its valueQuotient */
export const TransformValueMapper =
  (valueExpressionMap: Map<string, ValueExpression>) => (kpiValue: { kpiId: string; value: number }) => {
    const targetExpression = Array.from(valueExpressionMap.values()).find(
      (ve) => ve.getSingleMetricTypeKey() === kpiValue.kpiId
    );
    if (!targetExpression) return;
    return {
      ...kpiValue,
      value: kpiValue.value / targetExpression.getRequiredMetricTypes()[0].type.valueQuotient,
    };
  };

export type HookInputParameters = {
  valueExpressions: ValueExpression[];
  orgKeys: string[];
  dateRange: LegacyDateRange;
  grouping?: LegacyWeekResolution | LegacyDateRangeGrouping;
  aggregation?: DateRangeAggregationInput;
  weekdayFilter?: WeekdaysFilter;
};

function mapEnum(grouping: LegacyWeekResolution | LegacyDateRangeGrouping): DateRangeGrouping {
  if (grouping in LegacyWeekResolution) {
    return mapWeekResolution(grouping as LegacyWeekResolution);
  } else if (grouping in LegacyDateRangeGrouping) {
    return mapLegacyDateRangeGrouping(grouping as LegacyDateRangeGrouping);
  }
}

const getDateFilter = (dateRange: DateRange, weekdayFilter?: WeekdaysFilter): AggregationDateFilterInput => {
  const { from, to } = {
    from: dateRange?.from?.format('YYYY-MM-DD'),
    to: dateRange?.to?.format('YYYY-MM-DD'),
  };
  return {
    range: {
      from: from,
      until: to,
    },
    weekdays: weekdayFilter
      ? Object.entries(weekdayFilter).map(([day, active]) => ({
          weekday: day.toUpperCase() as WeekdayInput,
          active,
        }))
      : null,
  };
};

/** Map our internal frontend input variables to API type */
const InterfaceMapper = (internal: HookInputParameters): KpisQueryVariables => ({
  requestId: generateRequestId(),
  kpis: internal.valueExpressions.map((ve) => ({ kpiId: ve?.getSingleMetricTypeKey() })),
  dateFilter: getDateFilter(internal.dateRange, internal.weekdayFilter),
  // as there is no WeekResolution.single we use DateRangeGrouping.SINGLE when no weekResolution is passed
  // when WeekResolution type is obsolete we can change this default
  dateRangeGrouping: internal.grouping ? mapEnum(internal.grouping) : DateRangeGrouping.Single,
  dateRangeAggregation: internal.aggregation ? internal.aggregation : DateRangeAggregationInput.Sum,
  orgKeys: internal.orgKeys,
});

const mapWeekResolution = (weekResolutionVal: LegacyWeekResolution): DateRangeGrouping => {
  switch (weekResolutionVal) {
    case LegacyWeekResolution.isoWeek:
      return DateRangeGrouping.WeekMonday;
    case LegacyWeekResolution.month:
      return DateRangeGrouping.Month;
    case LegacyWeekResolution.day:
      return DateRangeGrouping.Day;
    case LegacyWeekResolution.week1:
      return DateRangeGrouping.WeekMonday;
    default:
      throw new Error(`Unknown type: ${weekResolutionVal}`);
  }
};

const mapLegacyDateRangeGrouping = (grouping: LegacyDateRangeGrouping): DateRangeGrouping => {
  switch (grouping) {
    case LegacyDateRangeGrouping.day:
      return DateRangeGrouping.Day;
    case LegacyDateRangeGrouping.month:
      return DateRangeGrouping.Month;
    case LegacyDateRangeGrouping.none:
      return DateRangeGrouping.Single;
    case LegacyDateRangeGrouping.week:
      return DateRangeGrouping.WeekMonday;
    case LegacyDateRangeGrouping.week0:
      return DateRangeGrouping.WeekSunday;
    case LegacyDateRangeGrouping.week1:
      return DateRangeGrouping.WeekMonday;
    case LegacyDateRangeGrouping.year:
      return DateRangeGrouping.Year;
    default:
      return DateRangeGrouping.Day;
  }
};

export default function useKpiQuery<TData = TransformedKpis, TError = unknown>(
  variables: HookInputParameters,
  options?: Omit<UseQueryOptions<KpisQuery, TError, TData>, 'select'>,
  select?: (data: TransformedKpis) => TData
): UseQueryResult<TData, TError> {
  const veMap = useValueExpressionContext();
  const token = useSelector(selectToken);
  const parameters = InterfaceMapper(variables);

  const dataSource = useMemo<{ endpoint: string; fetchParams?: RequestInit }>(
    () => ({
      endpoint: getEnvVariable(ConfigKey.GQL_API_URL),
      fetchParams: {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      },
    }),
    [token]
  );
  return useKpisQuery<TData, TError>(dataSource, parameters, {
    ...options,
    select: (data) => {
      const filteredGroups = data.kpis.groups.map((group) => ({
        ...group,
        kpiValues: group.kpiValues.filter(NotAvailableFilter).map(ParseFloatMapper).map(TransformValueMapper(veMap)),
      }));
      const filteredSummaryKpiValues = data.kpis.summary.kpiValues
        .filter(NotAvailableFilter)
        .map(ParseFloatMapper)
        .map(TransformValueMapper(veMap));
      const result: TransformedKpis = {
        kpis: { ...data.kpis, groups: filteredGroups, summary: { kpiValues: filteredSummaryKpiValues } },
      };
      return select ? select(result) : (result as TData);
    },
  });
}
