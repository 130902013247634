import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'lma-anomalies.s.colli-teillieferung.count',
    label: 'Teillieferung (mehrcollig)',
    sumLabel: 'Teillieferung',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    definition: getDefinitionByName('Teillieferung (mehrcollig)'),
  })
);
