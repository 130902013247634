import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const expression = new ValueExpression(
  new MetricType({
    key: 'produktivitaet-ps',
    label: 'PS-Produktivität',
    definition: getDefinitionByName('PS-Produktivität'),
    positiveDirection: PositiveDirection.custom,
    // da die Netto-PS-Zeit (Nenner) bei der Berechnung in Sekunden ausgewiesen ist,
    // wir aber die Produktivität in Stunden haben wollen
    valueQuotient: 1 / 3600,
    valueFormat: '-#.###.##0,0',
  })
);

expression.targetValueType = TargetValueType.QUOTE;

export default expression;
