import MetricType, { shipmentTypeMap, shipmentTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Lademenge nach Sendungsart';
const key = 'lma-shipmentbased.TA.relevant.count.by_shipment_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${category.label}`,
    aggregation: AggregationType.sum,
    hidden: true,
    positiveDirection: PositiveDirection.custom,
    sumLabel: 'Lademenge',
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;

const sumExpression = new ValueExpression(sumType);

sumExpression.getChildren = () =>
  shipmentTypes.map((type) => {
    return new ValueExpression(getMetricType({ key: type, label: shipmentTypeMap[type] }));
  });

export default sumExpression;
