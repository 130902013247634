import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const metric = new MetricType({
  key: 'tourdauer_rueckfahrt',
  label: 'Rückfahrtzeit',
  valueFormat: '-#.###.##0,0 h',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  category: 'tours',
  description: '',
  hidden: true,
  definition: getDefinitionByName('Rückfahrtzeit'),
  valueQuotient: 3600,
});

const expression = new ValueExpression(metric);

export default expression;
