import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'boimport.abwicklungsmenge.all.count.total',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName('Abwicklungsmenge'),
  label: 'Abwicklungsmenge',
  sumLabel: 'Abwicklungsmenge',
});

export default new ValueExpression(metric);
