import MetricType, { undeliverableReasonsShipmentBased } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import {
  UndeliverableReasonExpressShortLabels,
  UndeliverableReasons,
} from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';

const label = 'Rückläufer Eil nach Grund';
const key = 'lma-shipmentbased.RL.express.count.by_undeliverable_reason';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${category.label}`,
    aggregation: AggregationType.sum,
    sumLabel: 'Rückläufer',
    category: 'delivery-items',
    positiveDirection: PositiveDirection.down,
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;

const sumExpression = new ValueExpression(sumType);

sumExpression.getChildren = () =>
  undeliverableReasonsShipmentBased.map((reason) => {
    const label = UndeliverableReasons[reason].label;
    const expression = new ValueExpression(getMetricType({ key: reason, label: label }));
    expression.shortLabel = UndeliverableReasonExpressShortLabels[reason];
    return expression;
  });

export default sumExpression;
