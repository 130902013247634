import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'tour_loaded.deliveryitems.customer.authorization.count',
  label: 'Sendungen mit Dauervollmacht',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'delivery-items',
  description:
    '<p>Anzahl aller auf der geladenen Tour geplanten Empfänger mit einer hinterlegten Dauervollmacht gezählt ' +
    'nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
});

export default new ValueExpression(metric);
