import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'sat.deliveryitems.intake.forecast.created_at',
  label: 'Prognostizierte Sendungseingangsmenge', // TODO: it has same label prognose-sendungseingangsmenge.ts Check it.
  aggregation: AggregationType.max,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'sat',
  description: '<p>Erstellungsdatum der Prognose für die Sendungseingangsmenge</p>\n',
  hidden: true,
});

const expression = new ValueExpression(metric);

export default expression;
