import { useCallback, useMemo } from 'react';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import ChartUtils from '../utils/ChartUtils';
import { ValuableComplaintReasonsColors } from '../../complaints/models/enums/ValuableComplaintsReason';
import { UndeliverableReasonColors } from '../../metrics2/models/enumerations/UndeliverableReason';
import { shadeColor } from '../../utils/color/colorUtils';
import getRandomHexColorByIndex from '../../utils/color/getRandomHexColorByIndex';
import MetricsDataProvider from '../../metrics2/services/MetricsDataProvider';
import { orderBy, zip } from 'lodash';
import { _isStacked, DatasetType } from '../charts/DetailedChart';
import { v4 as uuidv4 } from 'uuid';
import { ChartType } from '../../metrics2/models/enumerations/ChartType';
import { Duration } from '../models/enums/Duration';

export type ValueExpressionMap = Map<string, ValueExpression>;

export const useChartDataTransformation = (
  showBenchmark: boolean,
  showMetricValues: boolean,
  activeDashboard: string,
  selectedChartType: ChartType,
  duration: Duration,
  data: { primary: any; compare: any },
  loading: boolean,
  valueExpressions: ValueExpression[],
  threshold: number
) => {
  const _mergeDatasets = (datasets: DatasetType[], label = 'Sonstige', color = '#c7c7c7') => {
    const ds: any = { ...datasets[0] };
    ds.label = label;
    ds.backgroundColor = color;
    ds.borderColor = shadeColor(color, -20);
    ds.details = orderBy(datasets, ['sum', (ds) => ds.label.toLowerCase()], ['desc', 'asc']);
    ds.data = zip(...datasets.map((ds) => ds.data)).map((ds) => {
      return ds.filter((v) => !isNaN(v)).reduce((a, b) => a + b, 0);
    });
    return ds;
  };

  const _groupDatasetByThreshold = useCallback(
    (datasets: DatasetType[]): DatasetType[] => {
      const total = datasets.map((d) => d.sum).reduce((a, b) => a + b, 0);
      const aboveThresholdDatasets = [];
      const underThresholdDatasets = [];
      datasets.forEach((ds) => {
        const percentage = (ds.sum / total) * 100;
        ds.percentage = percentage;
        if (percentage <= threshold) {
          underThresholdDatasets.push(ds);
        } else {
          aboveThresholdDatasets.push(ds);
        }
      });

      if (underThresholdDatasets.length > 0) {
        const groupedDataset = _mergeDatasets(underThresholdDatasets);
        return [...aboveThresholdDatasets, groupedDataset];
      }
      return aboveThresholdDatasets;
    },
    [threshold]
  );

  const chartData = useMemo(() => {
    const _cleanDatasetFromNullValues = (datasets: DatasetType[]) => {
      return datasets.map((ds) => {
        const data = ds.data.map((value, i) => {
          if (typeof value === 'number' && value > 1) {
            return value;
          }
          const filteredDatasets = datasets.filter((ds) => typeof ds.data[i] === 'number' && ds.data[i] > 0);
          if (filteredDatasets.length > 0) {
            return value;
          }
          return null;
        });
        return { ...ds, ...{ data } };
      });
    };

    const isMengenPrognose = activeDashboard === 'mengenprognose';
    const dateRange = ChartUtils.getDateRange(duration);
    const dateRanges = isMengenPrognose
      ? ChartUtils.getPrognoseDateRanges(dateRange.from, dateRange.to)
      : ChartUtils.getDateRanges(dateRange.from, dateRange.to);
    const comparisonRange = ChartUtils.getComparisonDateRange(duration);
    const compareRanges = ChartUtils.getDateRanges(comparisonRange.from, comparisonRange.to);
    let datasets: DatasetType[] = [];
    let target = 0;
    const stacked = _isStacked(selectedChartType);

    if (valueExpressions == null || valueExpressions.length == 0 || loading || !data) {
      return null;
    }

    if (valueExpressions.length > 1) {
      datasets = valueExpressions
        .map((valueExpression, i) => {
          const typeKey = valueExpression.getSingleMetricTypeKey();

          const color =
            ValuableComplaintReasonsColors[typeKey] ||
            UndeliverableReasonColors[typeKey] ||
            shadeColor(getRandomHexColorByIndex(i), -20);

          const label = valueExpression.getLabel();

          return {
            primary: true,
            label: label,
            lineTension: 0,
            spanGaps: true,
            pointRadius: dateRanges.resolution === 'isoWeek' ? 2 : 3,
            pointBorderWidth: 2,
            pointBackgroundColor: '#FFFFFF',
            fill: !!stacked,
            backgroundColor: color,
            borderColor: shadeColor(color, -20),
            //  map data values
            data: dateRanges.dates.map((dt) => {
              const dateKey = `${dt.format('YYYY-MM-DD')}_${MetricsDataProvider.translateMomentRangeToGrouping(
                dateRanges.resolution
              )}_${typeKey}`;
              if (data.primary[dateKey]) {
                return data.primary[dateKey];
              }
              return 0;
            }),
          };
        }) // add sum to sort datasets afterwards
        // this is needed so the legend is ordered the same the chart is
        .map((ds) => {
          const sum = ds.data.filter((s) => !isNaN(s)).reduce((a, b) => a + b, 0);
          return { ...ds, ...{ sum } };
        });
      datasets = _groupDatasetByThreshold(datasets);
      datasets = _cleanDatasetFromNullValues(datasets);
      datasets = orderBy(datasets, ['sum', (ds: DatasetType) => ds.label.toLowerCase()], ['asc', 'asc']);
    } else {
      const valueExpression = valueExpressions[0];
      datasets = [
        {
          primary: true,
          label: valueExpression.getLabel(),
          lineTension: 0,
          pointRadius: 3,
          pointBorderWidth: 2,
          pointBackgroundColor: '#FFFFFF',
          spanGaps: true,
          fill: !!stacked,
          borderColor: '#0091cd',
          backgroundColor: '#0091cd',
          data: dateRanges.dates.map((dt) => {
            const typeKey = valueExpression.getSingleMetricTypeKey();
            const dateKey = `${dt.format('YYYY-MM-DD')}_${MetricsDataProvider.translateMomentRangeToGrouping(
              dateRanges.resolution
            )}_${typeKey}`;
            if (data.primary[dateKey]) {
              return data.primary[dateKey];
            }
            return null;
          }),
        },
      ].map((ds) => {
        const data: Array<number | null | undefined> = ds.data.map((value) =>
          typeof value === 'number' && value > 0 ? value : null
        );
        return { ...ds, ...{ data } };
      });

      if (!showMetricValues && !isMengenPrognose) {
        const typeKey = valueExpression.getSingleMetricTypeKey();

        const compareDatasets = [
          {
            compare: true,
            label: `Vergleich ${valueExpression.getLabel()}`,
            spanGaps: true,
            lineTension: 0,
            pointRadius: 2,
            pointHitRadius: 2,
            borderColor: 'rgba(252, 152, 0, 0.4)',
            backgroundColor: 'rgba(252, 152, 0, 0.4)',
            borderDash: [5, 2],
            fill: false,
            data: compareRanges.dates.map((dt) => {
              const dateKey = `${dt.format('YYYY-MM-DD')}_${MetricsDataProvider.translateMomentRangeToGrouping(
                dateRanges.resolution
              )}_${typeKey}`;
              return data.compare[dateKey];
            }),
          },
        ].map((ds) => {
          const data = ds.data.map((value) => (typeof value === 'number' && value > 0 ? value : null));
          return { ...ds, ...{ data } };
        });

        datasets = [...compareDatasets, ...datasets];

        // @Todo: this targetData is for demo purpose only right now
        // this has to bee configured somewhere else as soon we now how
        // define some benchmark / target values
        // for now it juts displayse the average value from the comparision daterange
        if (showBenchmark) {
          const targetData = compareDatasets[0].data.map((d) => (d ? d : 0));
          target = targetData.reduce((a, b) => a + b, 0) / targetData.filter((v) => v > 0).length;
          const data = new Array(Math.max(compareRanges.dates.length, dateRanges.dates.length) + 1).fill(null);
          data[0] = target;
          data[data.length - 1] = target;

          const targetDatasets = [
            {
              compare: true,
              label: 'Zielwert',
              spanGaps: true,
              lineTension: 0,
              pointRadius: 0,
              pointHitRadius: 0,
              borderWidth: 2,
              pointBorderWidth: 2,
              tooltop: false,
              borderColor: '#f98dca',
              backgroundColor: '#f98dca',
              pointBackgroundColor: '#f31a95',
              fill: false,
              data,
            },
          ];
          datasets = [...datasets, ...targetDatasets];
        }
      }

      datasets = _cleanDatasetFromNullValues(datasets);
    }

    return {
      labels: dateRanges.axisLabels,
      dataLabels: dateRanges.dataLabels,
      compareDataLabels: compareRanges.dataLabels,
      labelValues: dateRanges.dates,
      datasets,
      target,
      loaded: true,
      id: uuidv4(),
    };
  }, [
    duration,
    activeDashboard,
    selectedChartType,
    data,
    loading,
    valueExpressions,
    showMetricValues,
    _groupDatasetByThreshold,
    showBenchmark,
  ]);

  return chartData;
};
