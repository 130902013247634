import { get } from 'lodash';

/**
 * Values are the keys used in .env* files
 */
export enum ConfigKey {
  APP_VERSION = 'APP_VERSION',
  HTTP_API_URL = 'HTTP_API_URL',
  WS_API_URL = 'WS_API_URL',
  GQL_API_URL = 'GQL_API_URL',
  MAPBOX_STYLE_DEFAULT = 'MAPBOX_STYLE_DEFAULT',
  MAPBOX_STYLE_SATELLITE = 'MAPBOX_STYLE_SATELLITE',
  MAPBOX_STYLE_DARK = 'MAPBOX_STYLE_DARK',
  MAPBOX_STYLE_STOPP = 'MAPBOX_STYLE_STOPP',
  MAPBOX_STYLE_STOPPSAT = 'MAPBOX_STYLE_STOPPSAT',
  MAPBOX_TOKEN = 'MAPBOX_TOKEN',
  MAPBOX_DEV_TOKEN = 'MAPBOX_DEV_TOKEN',
  KEYCLOAK_REALM = 'KEYCLOAK_REALM',
  KEYCLOAK_AUTH_SERVER_URL = 'KEYCLOAK_AUTH_SERVER_URL',
  KEYCLOAK_SSL_REQUIRED = 'KEYCLOAK_SSL_REQUIRED',
  KEYCLOAK_CLIENT = 'KEYCLOAK_CLIENT',
  KEYCLOAK_PUBLIC_CLIENT = 'KEYCLOAK_PUBLIC_CLIENT',
  KEYCLOAK_CONFIDENTIAL_PORT = 'KEYCLOAK_CONFIDENTIAL_PORT',
  SENTRY_DSN = 'SENTRY_DSN',
}

/**
 * Look up '_env_' object in global window on non-local environment,
 * otherwise check node process env values.
 */
export function getEnvVariable(key: ConfigKey) {
  if (isInDevelopmentEnvironment()) {
    return process.env[key];
  }

  return get(window, `_env_[${key}]`);
}

export function isInDevelopmentEnvironment() {
  return ['development', 'test'].includes(process.env.NODE_ENV);
}

export function getKeycloakConfiguration() {
  return {
    url: getEnvVariable(ConfigKey.KEYCLOAK_AUTH_SERVER_URL),
    realm: getEnvVariable(ConfigKey.KEYCLOAK_REALM),
    resource: getEnvVariable(ConfigKey.KEYCLOAK_CLIENT),
  };
}

export function getMapboxStyles() {
  return {
    default: getEnvVariable(ConfigKey.MAPBOX_STYLE_DEFAULT),
    satellite: getEnvVariable(ConfigKey.MAPBOX_STYLE_SATELLITE),
    dark: getEnvVariable(ConfigKey.MAPBOX_STYLE_DARK),
    stopp: getEnvVariable(ConfigKey.MAPBOX_STYLE_STOPP),
    stoppSat: getEnvVariable(ConfigKey.MAPBOX_STYLE_STOPPSAT),
  };
}
