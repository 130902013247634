import moment from 'moment-timezone';
import ValueExpression from '../valueexpressions/ValueExpression';
import ValueExpressionBaseQuery from './ValueExpressionBaseQuery';
import MetricType from '../../../metrics2/models/entities/MetricType';
import MetricsQuery from '../../../metrics2/models/queries/MetricsQuery';
import { DateRangeGrouping } from '../../../metrics2/models/enumerations/DateRangeGrouping';
import { WeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';

export default class ValueExpressionQuery extends ValueExpressionBaseQuery {
  constructor(
    public readonly valueExpression: ValueExpression,
    private readonly orgKey: string,
    dateFrom: moment.Moment,
    dateUntil: moment.Moment,
    public weekdayFilter: WeekdaysFilter,
    private readonly grouping: DateRangeGrouping = DateRangeGrouping.day,
    valueKey: string | null = null,
    private readonly contractorKey: string | null = null
  ) {
    super(dateFrom, dateUntil, weekdayFilter, valueKey);
  }

  getMetricQueries(): Array<MetricsQuery> {
    return this.valueExpression
      .getRequiredMetricTypes()
      .map(({ type, valueKey }: { type: MetricType; valueKey?: string; id: string }) => {
        return new MetricsQuery(
          type.key,
          this.orgKey,
          this.dateFrom,
          this.dateUntil,
          this.grouping,
          valueKey,
          this.contractorKey,
          this.weekdayFilter,
          type.aggregation
        );
      });
  }
}
