import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const expression = new ValueExpression(
  new MetricType({
    key: 'benachrichtigung_email_quote',
    label: 'Digitale Benachrichtigungsquote',
    valueFormat: '-#.###.##0,#0%',
    valueQuotient: 0.01,
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    definition: getDefinitionByName('Digitale Benachrichtigungsquote'),
  })
);

expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
