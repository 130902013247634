import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const expression = new ValueExpression(
  new MetricType({
    key: 'zustellzeit_brutto',
    label: 'Brutto-Zustellzeit',
    valueFormat: '#.###.##0,0 h',
    valueQuotient: 3600,
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    definition: getDefinitionByName('Brutto-Zustellzeit'),
  })
);

expression.targetValueType = TargetValueType.SUM;

export default expression;
