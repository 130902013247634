import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'tour_loaded.count',
  label: 'Einsätze',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'tours',
  description: '<p>Anzahl aller geladenen Touren gezählt nach dem ersten abgeschlossenen Zustellvorgang</p>\n',
  definition: getDefinitionByName('Einsätze (Touren)'),
});

export default new ValueExpression(metric);
