import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'zsb_plus_0_eil_quote',
  label: 'ZSB+0 Eil',
  definition: getDefinitionByName('ZSB+0 Eil'),
  positiveDirection: PositiveDirection.up,
  valueQuotient: 0.01,
  valueFormat: '-#.###.##0,#0%',
});

const expression = new ValueExpression(metric);

expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;
expression.chartRenderOptions.startYAxisOnZero = false;

export default expression;
