import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'Außerhalb WZF',
  label: 'Außerhalb WZF',
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##',
});

const expression = new ValueExpression(metric);

expression.shortLabel = 'Außerhalb WZF';

export default expression;
