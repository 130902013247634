import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import {
  UndeliverableReason,
  UndeliverableReasons,
} from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';

const label = 'Rücklauf Abholkarten nach Grund';
const key = 'lma-shipmentbased.RAA.all.count.by_undeliverable_reason';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${category.label}`,
    aggregation: AggregationType.sum,
    hidden: true,
    positiveDirection: PositiveDirection.down,
    sumLabel: 'Rückläufer',
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;

const sumExpression = new ValueExpression(sumType);

const raaReasons: UndeliverableReason[] = [
  'Canceled',
  'DeliveryRefused',
  'NoMoney',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'WrongAddress',
];

sumExpression.getChildren = () =>
  raaReasons.map((reason) => {
    const label = UndeliverableReasons[reason].label;
    return new ValueExpression(getMetricType({ key: reason, label: label }));
  });

export default sumExpression;
