import { useApiContext } from '@contexts/api-context';
import { KnownWebsocketEvent } from '@contexts/api-context/request.types';
import { useOverviewContext } from '@contexts/overview-context';
import MetricsEntityKey from '@legacy-modules/metrics2/models/entities/MetricsEntityKey';
import { DateRangeGrouping } from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import MetricsOverviewQueryResponsePayload from '@legacy-modules/metrics2/models/websocket/metrics/MetricsOverviewQueryResponsePayload';
import MetricsQueryResponsePayload from '@legacy-modules/metrics2/models/websocket/metrics/MetricsQueryResponsePayload';
import OrgUnitMultiResponsePayload from '@legacy-modules/metrics2/models/websocket/org/OrgUnitMultiResponsePayload';
import { OrgUnitResult } from '@legacy-modules/metrics2/models/websocket/org/OrgUnitResult';
import {
  selectCompareFilter,
  selectOverviewValueExpression,
  selectPrimaryFilter,
  selectOverviewWeekdayFilter,
  selectPrimaryText,
  selectCompareText,
} from '@redux';
import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import * as fs from 'fs';

XLSX.set_fs(fs);

export type ExportType = 'time' | 'organization';
export type ExportFormat = 'xlsx' | 'csv';
type UseOverviewExportOutput = (type: ExportType, format: ExportFormat) => Promise<void>;

export default function useOverviewExport(rootOrgUnits: OrgUnitResult[]): UseOverviewExportOutput {
  const primaryFilter = useSelector(selectPrimaryFilter);
  const compareFilter = useSelector(selectCompareFilter);
  const { selectedTreeTypesState } = useOverviewContext();
  const valueExpression = useSelector(selectOverviewValueExpression);
  const weekdayFilter = useSelector(selectOverviewWeekdayFilter);
  const primaryText = useSelector(selectPrimaryText);
  const compareText = useSelector(selectCompareText);
  const apiCtx = useApiContext();

  const makeDokument = useCallback(
    (
      primaryData: Map<MetricsEntityKey, number>,
      compareData: Map<MetricsEntityKey, number>,
      orgData: OrgUnitResult[],
      format: ExportFormat
    ) => {
      const primarySheetData = Array.from(primaryData.entries())
        .sort(([keyA], [keyB]) => {
          if (keyA.dateFrom.isSame(keyB.dateFrom)) {
            return 0;
          }
          return keyA.dateFrom.isBefore(keyB.dateFrom) ? -1 : 1;
        })
        .map(([key, value]) => {
          if (compareData?.size > 0) {
            return {
              label: valueExpression.getLabel(),
              group:
                format === 'xlsx'
                  ? `Primär (${primaryText})`
                  : `Primär (${key.dateFrom.format('DD.MMM')} - ${key.dateUntil.format('DD.MMM')})`,
              organization: orgData.find((org) => org.orgKey === key.orgKey)?.name,
              date:
                format === 'xlsx'
                  ? key.dateFrom.format('DD.MM.YYYY')
                  : `${key.dateFrom.format('DD.MM.YYYY')} - ${key.dateUntil.format('DD.MM.YYYY')}`,
              value: valueExpression.getValueFormatter()(value),
            };
          } else {
            return {
              label: valueExpression.getLabel(),
              organization: orgData.find((org) => org.orgKey === key.orgKey)?.name,
              date:
                format === 'xlsx'
                  ? key.dateFrom.format('DD.MM.YYYY')
                  : `${key.dateFrom.format('DD.MM.YYYY')} - ${key.dateUntil.format('DD.MM.YYYY')}`,
              value: valueExpression.getValueFormatter()(value),
            };
          }
        });
      const compareSheetData =
        compareData?.size > 0
          ? Array.from(compareData.entries())
              .sort(([keyA], [keyB]) => {
                if (keyA.dateFrom.isSame(keyB.dateFrom)) {
                  return 0;
                }
                return keyA.dateFrom.isBefore(keyB.dateFrom) ? -1 : 1;
              })
              .map(([key, value]) => ({
                label: valueExpression.getLabel(),
                group:
                  format === 'xlsx'
                    ? `Vergleich (${compareText})`
                    : `Vergleich (${key.dateFrom.format('DD.MMM')} - ${key.dateUntil.format('DD.MMM')})`,
                organization: orgData.find((org) => org.orgKey === key.orgKey)?.name,
                date:
                  format === 'xlsx'
                    ? key.dateFrom.format('DD.MM.YYYY')
                    : `${key.dateFrom.format('DD.MM.YYYY')} - ${key.dateUntil.format('DD.MM.YYYY')}`,
                value: valueExpression.getValueFormatter()(value),
              }))
          : [];
      const worksheet =
        format === 'xlsx'
          ? XLSX.utils.aoa_to_sheet([
              ['Last Mile Analytics Export'],
              ['Zeitraum:', primaryText],
              ['Zeitraum:', compareText],
              ['Organisation:', rootOrgUnits?.map((orgUnit) => orgUnit?.name).join(', ') ?? ''],
              [],
              compareSheetData?.length > 0
                ? ['Anzeigewert', 'Primär / Vergleich', 'Organisation', 'Datum', 'Wert']
                : ['Anzeigewert', 'Organisation', 'Datum', 'Wert'],
              ...[...primarySheetData, ...compareSheetData].map((data) =>
                Object.values(data).map((value) => value ?? '-')
              ),
            ])
          : XLSX.utils.aoa_to_sheet([
              compareSheetData?.length > 0
                ? ['Anzeigewert', 'Primär / Vergleich', 'Organisation', 'Datum', 'Wert']
                : ['Anzeigewert', 'Organisation', 'Datum', 'Wert'],
              ...[...primarySheetData, ...compareSheetData].map((data) =>
                Object.values(data).map((value) => value ?? '-')
              ),
            ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Last Mile Analytics');
      XLSX.writeFile(workbook, `export.` + format);
    },
    [valueExpression, primaryText, compareText, rootOrgUnits]
  );

  const fetchByOrganization = useCallback(
    async (rootOrgKeys: string[], format: ExportFormat) => {
      const orgKeys = rootOrgUnits
        ?.flatMap((org) => org.children)
        ?.concat(rootOrgKeys)
        .filter((key) => !key.startsWith('oh'));
      const orgUnits: OrgUnitMultiResponsePayload = await apiCtx.wsFetch(
        KnownWebsocketEvent.MULTI_ORG_TREE_LOAD_EVENT,
        {
          orgKeys,
          parts: ['properties'],
          orgType: selectedTreeTypesState?.[rootOrgUnits[0].orgType],
          from: primaryFilter.from,
          to: primaryFilter.to,
        }
      );
      const primaryResponse = await Promise.all(
        valueExpression.getRequiredMetricTypes().map(({ type, valueKey }) =>
          apiCtx.wsFetch<[MetricsEntityKey, number][], Pick<MetricsOverviewQueryResponsePayload, 'values'>>(
            KnownWebsocketEvent.METRICS_OVERVIEW_LOAD_EVENT,
            {
              types: [type.key],
              orgKeys: orgUnits?.units?.map((unit) => unit?.orgKey),
              dateFilter: {
                range: {
                  from: primaryFilter.from.format('YYYY-MM-DD'),
                  until: primaryFilter.to.format('YYYY-MM-DD'),
                },
                weekdays: weekdayFilter,
              },
              valueKey,
              aggregations: [type?.aggregation],
              contractorKey: null,
            },
            (data) =>
              data?.values?.map((value) => [
                new MetricsEntityKey(
                  value.type,
                  value.group,
                  primaryFilter.from,
                  primaryFilter.to,
                  DateRangeGrouping.none,
                  valueKey,
                  weekdayFilter
                ),
                value.value,
              ])
          )
        )
      );
      const compareResponse = compareFilter
        ? await Promise.all(
            valueExpression.getRequiredMetricTypes().map(({ type, valueKey }) =>
              apiCtx.wsFetch<[MetricsEntityKey, number][], Pick<MetricsOverviewQueryResponsePayload, 'values'>>(
                KnownWebsocketEvent.METRICS_OVERVIEW_LOAD_EVENT,
                {
                  types: [type.key],
                  orgKeys: orgUnits?.units?.map((unit) => unit?.orgKey),
                  dateFilter: {
                    range: {
                      from: compareFilter.from.format('YYYY-MM-DD'),
                      until: compareFilter.to.format('YYYY-MM-DD'),
                    },
                    weekdays: weekdayFilter,
                  },
                  valueKey,
                  aggregations: [type?.aggregation],
                  contractorKey: null,
                },
                (data) =>
                  data?.values?.map((value) => [
                    new MetricsEntityKey(
                      value.type,
                      value.group,
                      compareFilter.from,
                      compareFilter.to,
                      DateRangeGrouping.none,
                      valueKey,
                      weekdayFilter
                    ),
                    value.value,
                  ])
              )
            )
          )
        : [];

      const primaryData = valueExpression.processValues(new Map(primaryResponse.flat()));
      const compareData = valueExpression.processValues(new Map(compareResponse.flat()));
      makeDokument(primaryData, compareData, orgUnits?.units, format);
    },
    [
      apiCtx,
      primaryFilter,
      compareFilter,
      selectedTreeTypesState,
      valueExpression,
      weekdayFilter,
      makeDokument,
      rootOrgUnits,
    ]
  );

  const fetchByTime = useCallback(
    async (rootOrgKeys: string[], format: ExportFormat) => {
      const orgUnits: OrgUnitMultiResponsePayload = await apiCtx.wsFetch(
        KnownWebsocketEvent.MULTI_ORG_TREE_LOAD_EVENT,
        {
          orgKeys: rootOrgKeys,
          parts: ['properties'],
          orgType: selectedTreeTypesState?.[rootOrgUnits[0].orgType],
          from: primaryFilter.from,
          to: primaryFilter.to,
        }
      );
      const primaryResponse = await Promise.all(
        rootOrgKeys.flatMap((orgKey) =>
          valueExpression.getRequiredMetricTypes().map(({ type, valueKey }) =>
            apiCtx.wsFetch<[MetricsEntityKey, number][], MetricsQueryResponsePayload>(
              KnownWebsocketEvent.METRICS_QUERY_LOAD_EVENT,
              {
                type: type.key,
                orgKey,
                dateFilter: {
                  range: {
                    from: primaryFilter.from.format('YYYY-MM-DD'),
                    until: primaryFilter.to.format('YYYY-MM-DD'),
                  },
                  weekdays: weekdayFilter,
                },
                grouping: DateRangeGrouping.day,
                valueKey,
                contractorKey: null,
                aggregation: type.aggregation,
              },
              (data) =>
                data?.values?.map((value) => [
                  new MetricsEntityKey(
                    data.type,
                    data.orgKey,
                    moment(value.group),
                    moment(value.group),
                    DateRangeGrouping.day,
                    valueKey,
                    weekdayFilter
                  ),
                  value.value,
                ])
            )
          )
        )
      );
      const compareResponse = compareFilter
        ? await Promise.all(
            rootOrgKeys.flatMap((orgKey) =>
              valueExpression.getRequiredMetricTypes().map(({ type, valueKey }) =>
                apiCtx.wsFetch<[MetricsEntityKey, number][], MetricsQueryResponsePayload>(
                  KnownWebsocketEvent.METRICS_QUERY_LOAD_EVENT,
                  {
                    type: type.key,
                    orgKey,
                    dateFilter: {
                      range: {
                        from: compareFilter.from.format('YYYY-MM-DD'),
                        until: compareFilter.to.format('YYYY-MM-DD'),
                      },
                      weekdays: weekdayFilter,
                    },
                    grouping: DateRangeGrouping.day,
                    valueKey,
                    contractorKey: null,
                    aggregation: type.aggregation,
                  },
                  (data) =>
                    data?.values?.map((value) => [
                      new MetricsEntityKey(
                        data.type,
                        data.orgKey,
                        moment(value.group),
                        moment(value.group),
                        DateRangeGrouping.day,
                        valueKey,
                        weekdayFilter
                      ),
                      value.value,
                    ])
                )
              )
            )
          )
        : [];
      const primaryData = valueExpression.processValues(new Map(primaryResponse.flat()));
      const compareData = valueExpression.processValues(new Map(compareResponse.flat()));
      makeDokument(primaryData, compareData, orgUnits?.units, format);
    },
    [
      apiCtx,
      primaryFilter,
      compareFilter,
      selectedTreeTypesState,
      valueExpression,
      weekdayFilter,
      makeDokument,
      rootOrgUnits,
    ]
  );

  const exportData = useCallback(
    async (type: ExportType, format: ExportFormat) => {
      if (!rootOrgUnits || rootOrgUnits?.length === 0) {
        return;
      }
      const rootOrgKeys = rootOrgUnits.map((org) => org.orgKey) ?? [];
      if (rootOrgUnits?.length === 1 && type === 'organization') {
        fetchByOrganization(rootOrgKeys, format);
      } else {
        fetchByTime(rootOrgKeys, format);
      }
    },
    [rootOrgUnits, fetchByOrganization, fetchByTime]
  );

  return exportData;
}
