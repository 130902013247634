import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'Nutzungsquote Foto-Unterschriften',
  label: 'Nutzungsquote Foto-Unterschriften',
  definition: getDefinitionByName('Nutzungsquote Foto-Unterschriften'),
  positiveDirection: PositiveDirection.up,
});

const expression = new ValueExpression(metric);

expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
