import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

export default new ValueExpression(
  new MetricType({
    key: 'lademenge_wert_ist',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    label: 'Wertsendungen auf Tour',
    chart: ChartType.bar,
    definition: getDefinitionByName('Wertsendungen auf Tour'),
  })
);
