import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Abholvorgänge nach Quelle';
const key = 'returns.finished_deliveries.by_source';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category}`,
    label: `${label} (${category})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'returns',
    description: '<p>Anzahl aller abgeschlossenen Abholvorgänge nach Quelle (Paketshop, Privat)</p>\n',
  });
};

const sumType = getMetricType('sum');
sumType.label = label;
sumType.chart = ChartType.line;

const abholvorgaengeQuelle = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
const categories = ['PaketShop', 'Tour', 'ProPS'];
abholvorgaengeQuelle.getChildren = () => categories.map((category) => new ValueExpression(getMetricType(category)));

export default abholvorgaengeQuelle;
