import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'fortschritt',
  label: 'Fortschritt',
  valueQuotient: 1 / 100,
  valueFormat: '-#.###.##0,#0%',
});

const expression = new ValueExpression(metric);
expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
