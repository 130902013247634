import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'rueckfahrtzeit/touren',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.down,
  label: 'Ø Rückfahrtzeit',
  definition: getDefinitionByName('Rückfahrtzeit'),
  chart: ChartType.bar,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
  category: 'tours',
});

const expression = new ValueExpression(metric);

expression.targetValueType = TargetValueType.QUOTE;

export default expression;
