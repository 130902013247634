import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Zustellvorgänge nach Empfängeranzahl';
const key = 'finished_deliveries.by_customer_count';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category}`,
    label: `${label} (${category})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'deliveries',
    description: '<p>Anzahl aller abgeschlossenen Zustellvorgänge nach Anzahl Empfänger pro Zustellvorgang</p>\n',
  });
};

const sumType = getMetricType('sum');
sumType.label = label;
sumType.chart = ChartType.line;

const finishedDeliveriesByCustomerCountSum = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
const categories = ['1', '2', '3', '4', '5-10', '>10'];
finishedDeliveriesByCustomerCountSum.getChildren = () =>
  categories.map((category) => new ValueExpression(getMetricType(category)));

export default finishedDeliveriesByCustomerCountSum;
