import Model, { DefaultType, MemberType } from '../../../utils/Model';
import { PositiveDirections } from './PositiveDirection';
import { PositiveDirection } from './PositiveDirection';
import { UndeliverableReason, UndeliverableReasonLabels } from '../enumerations/UndeliverableReason';
import { ValueType } from '../enumerations/ValueType';
import { AggregationType } from '../enumerations/AggregationType';
import { ChartType } from '../enumerations/ChartType';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

const dayHours = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const complaintReasons = [
  'BNK - Keine',
  'Verspätung',
  'F-Adresse ist richtig',
  'unklarer Sendungsverbleib',
  'unklarer Abliefernachweis',
  'Fehlerhafte BNK',
  'Umverfügung nicht eingehalten',
  'fehlerhafte Scannung',
  'fehlerhafte A-Scannung',
  'fehlerhafte N-Scannung',
  'Fehler bei der Abholung',
  'Fehler bei der Zustellung',
  'Unerwünschte Zustellung am PaketShop (PS)',
  'Kontaktlose Zustellung nicht erfolgt',
  'Abholung nicht erfolgt',
  'keine Quittung',
  'Paketankündigung nicht eingehalten',
  'Schaden gemeldet',
  'Inhalt fehlt (Teilverlust)',
  'Haftpflichtschaden',
  'Korrekturbeleg eingetroffen',
  'Widerspruch nach OPS Klärung',
  'Widerspruch zur Haftungsentscheidung',
  'Rückzahlung von KD/ATG',
  'Zahlungsrückläufer',
  'Unfreundlicher Mitarbeiter',
  'Prävention',
  'Ungepflegtes Erscheinungsbild',
  'Sendung verschmutzt/riecht nach Rauch',
  'Fehlerhafte Datenänderung',
  'sprachliche Probleme / Verständigungsprobleme',
  'unzureichende / falsche Beratung',
  'Ermittlung durch Polizei/Staatsanwaltschaft',
  'Bearbeitungszeit überschritten',
  'Reklamation unvollständig abgeschlossen',
  'PaketShop existiert nicht mehr/ falsche Adresse',
  'Öffnungszeiten des Paketshops wurden nicht eingehalten',
  'Hermes App - allgemeine Probleme',
  'Hermes App - Verbindungsabbrüche',
  'Hermes App - Sendungsverfolgung fehlerhaft',
];

export const undeliverableReasons: UndeliverableReason[] = [
  'AuthorityNotFound',
  'AuthorityNotPossible',
  'Canceled',
  'DeliveryItemDamaged',
  'DeliveryRefused',
  'Ident',
  'IdentAge',
  'IdentBirthday',
  'IdentDocument',
  'IdentName',
  'IdentPicture',
  'IdentPin',
  'IdVerificationFailed',
  'Loss',
  'MinAgeVerificationFailed',
  'MisroutedDeliveryItem',
  'NameVerificationFailed',
  'NoMoney',
  'NotMet',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'Tan1',
  'Tan2',
  'Tan3',
  'Tan4',
  'NotPickedUp',
  'PackagingDamaged',
  'PictureVerificationFailed',
  'RLoGGS',
  'SingleAuthorityNotPossible',
  'TooLate',
  'WrongAddress',
  'UNB',
];

export const undeliverableReasonsShipmentBased: UndeliverableReason[] = [
  'Canceled',
  'IdentAge',
  'DeliveryItemDamaged',
  'DeliveryRefused',
  'IdentDocument',
  'IdentName',
  'IdentPicture',
  'MisroutedDeliveryItem',
  'NoMoney',
  'NotMet1',
  'NotMet2',
  'NotMet3',
  'NotMet4',
  'Tan1',
  'Tan2',
  'Tan3',
  'Tan4',
  'RLoGGS',
  'TooLate',
  'WrongAddress',
];

export const shipmentTypes = [
  'ABA',
  'BRFBU',
  'BRFST',
  'FAH',
  'GEPSO',
  'GEPST',
  'GRST',
  'HERVP',
  'HPGE',
  'HPST',
  'KATST',
  'KATUN',
  'KFS',
  'KRTS',
  'KWS',
  'PKT',
  'SG',
  'SKI',
  'TUE',
  'LTUE',
  'UNB',
];

export const shipmentTypeMap = {
  ABA: 'Abholauftrag',
  BRFBU: 'Businessbrief',
  BRFST: 'Brief Standard',
  FAH: 'Fahrrad',
  GEPSO: 'Sondergepäck',
  GEPST: 'Gepäck Standard',
  GRST: 'Großstück',
  HERVP: 'Herstellerverpackung',
  HPGE: 'Hängepaket gebündelt',
  HPST: 'Hängepaket Standard',
  KATST: 'Katalog Standard',
  KATUN: 'Katalog unfoliert',
  KFS: 'Krankenfahrstuhl',
  KRTS: 'Kartusche',
  KWS: 'Kleinwarensendung',
  PKT: 'Paket',
  SG: 'Servicegebühr',
  SKI: 'Ski',
  TUE: 'Tüte',
  LTUE: 'Leichttüte',
  UNB: 'Unbekannt',
};

const sourceMap = {
  ProPS: 'ProPS',
  PaketShop: 'Paketshop',
  Tour: 'Tour',
};
export const abhMitReturnTypes = ['Gepäck', 'PriPS', 'Retoure'];
export const abhMitReturnTypeMap = {
  Gepäck: 'Gepäck',
  PriPS: 'PriPS',
  Retoure: 'Retoure',
};

const deliverySources = ['PaketShop', 'Tour', 'ProPS'];

const byItemCount = ['1', '2', '3', '4', '5-10', '11-20', '21-100', '>100'];

export const notificationTypes = ['card', 'email'];

const mapReducer = (acc, val) => {
  acc[val] = val;
  return acc;
};
const complaintReasonsMap = complaintReasons.reduce(mapReducer, {});

export type ChartRenderOptions = {
  // If true, the chart will always start on 0 on the y-axis. This can result in a lot of white spaces if all
  // values are close to each other and far away from zero, e.g. high percentage numbers (98% - 100%).
  startYAxisOnZero?: boolean;
};

export const DefaultChartRenderOptions = {
  startYAxisOnZero: true,
};

export const mapKeys = {
  'finished_deliveries.stop_journey.location.accuracy': ['0-5', '6-10', '11-20', '21-50', '51-100', '101-200', '>200'],
  'returns.finished_deliveries.by_hour': dayHours,
  'returns.finished_deliveries.by_source': deliverySources,
  'returns.finished_deliveries.by_item_count': byItemCount,
  'returns.finished_deliveries.items.by_hour': dayHours,
  'returns.finished_deliveries.items.by_source': deliverySources,
  'returns.finished_deliveries.items.by_type': [
    'ReturnToSender',
    'NewParcel',
    'PriPS',
    'ProPS',
    'DBCourier',
    'Undeliverable',
  ],
  'finished_deliveries.by_customer_count': ['1', '2', '3', '4', '5-10', '>10'],
  'finished_deliveries.by_item_count': byItemCount,
  'finished_deliveries.items.delivered.by_hour': dayHours,
  'finished_deliveries.items.delivered.by_recipient_person': [
    'atDoor',
    'intendedReceiver',
    'nextDoor',
    'reception',
    'staff',
    'paketShop',
    'props',
  ],
  'finished_deliveries.items.undeliverable.by_hour': dayHours,
  'finished_deliveries.items.undeliverable.by_reason': undeliverableReasons,
  'finished_deliveries.notifications.by_type': notificationTypes,
  'finished_deliveries.proof_of_delivery.by_type': ['none', 'image', 'signature', 'imageSignature'],
  'finished_deliveries.items.delivered.by_type': shipmentTypes,
  'tour_loaded.deliveryitems.types': shipmentTypes,
  'complaints.reported.count_by_type': complaintReasons,
};

export const mapKeyLabels = {
  'returns.finished_deliveries.by_source': sourceMap,
  'returns.finished_deliveries.items.by_source': sourceMap,
  'returns.finished_deliveries.items.by_type': {
    ReturnToSender: 'Retoure',
    NewParcel: 'PriPS/ProPS',
    PriPS: 'PriPS',
    ProPS: 'ProPS',
    DBCourier: 'DB Kuriergepäck',
    Undeliverable: 'Paketshop Rücklauf',
  },
  'finished_deliveries.items.by_delivery_mode': {
    props: 'ProPS',
    paketShop: 'PaketShop',
    'putDownOutside:oneTimeAuthorization': 'Einmalvollmacht',
    'putDownOutside:permanentAuthorization': 'Dauervollmacht',
    'putDownOutside:bnkAuthorization': 'BNK-Vollmacht',
    'putDownOutside:unknownAuthorization': 'Vollmacht',
    person: 'Zugestellt',
    undeliverable: 'Rückläufer',
    // backward compatibility
    putDownOutside: 'Dauervollmacht',
    oneTimeAuthorizationPutDown: 'Einmalvollmacht',
  },
  'finished_deliveries.items.delivered.by_recipient_person': {
    atDoor: 'In Wohnung',
    intendedReceiver: 'Originalempfänger',
    nextDoor: 'Nachbar',
    reception: 'Rezeption',
    staff: 'Mitarbeiter',
  },
  'finished_deliveries.items.undeliverable.by_reason': UndeliverableReasonLabels,
  'finished_deliveries.items.delivered.by_type': shipmentTypeMap,
  'tour_loaded.deliveryitems.types': shipmentTypeMap,
  'complaints.reported.count_by_type': complaintReasonsMap,
};

export default class MetricType extends Model<MetricType> {
  label: string;
  key: string;
  aggregation: AggregationType;
  positiveDirection: PositiveDirections;
  category: string | null | undefined;
  description: string | null | undefined;
  valueFormat: string;
  sumLabel: string | null | undefined;
  hidden: boolean | null | undefined;
  definition?: KpiDefinitionWithoutName;
  chart: ChartType;
  valueQuotient: number = 1;

  constructor(source: Partial<MetricType> = null, shallow: boolean = false) {
    super();
    this.hydrateFrom(source, shallow, this.getDefaults(), this.getMembers());
  }

  isPositiveChange(change: number): boolean {
    if (this.positiveDirection === PositiveDirection.up) {
      return change > 0;
    } else {
      return change < 0;
    }
  }

  static mapKeys = mapKeys;
  static mapKeyLabels = mapKeyLabels;

  get mapKeys(): Array<string> {
    if (MetricType.mapKeys[this.key]) {
      return MetricType.mapKeys[this.key];
    }
    return [];
  }

  get mapKeyLabels(): {
    [key: string]: string;
  } {
    if (MetricType.mapKeyLabels[this.key]) {
      return MetricType.mapKeyLabels[this.key];
    }
    return {};
  }

  getDefaults(): DefaultType<MetricType> {
    return {
      valueFormat: () => '-#.###.##0',
      hidden: () => false,
    };
  }

  getMembers(): MemberType<MetricType> {
    return undefined;
  }
}
