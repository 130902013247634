import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'zustellungen',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    label: 'Zustellungen',
    definition: getDefinitionByName('Zustellungen'),
    description: '<p>Anzahl erfolgreich zugestellter Sendungen (kein Rücklauf)</p>\n',
  })
);
