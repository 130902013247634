import { DataTableComponentId, DataTableUserConfig } from '@data-table/data-table.types';
import { UpdateUserConfigFn, useUserConfig } from '@hooks/use-user-config-hook';
import { createDurationFromPlainObject, Duration, Durations } from '@legacy-modules/dashboard/models/enums/Duration';
import { useCallback, useMemo } from 'react';
import { useDataTableOrgKey } from '../use-data-table-org-key-hook';
import { useValueExpressionContext } from '@contexts/value-expression-context';
import useMetricQuery from '@hooks/use-metric-query-hook/use-metric-query.hook';
import Touren from '@contexts/value-expression-context/value-expressions/touren';
import { DateRangeAggregationInput } from '@graphql-client/graphql';

const defaultDuration = Durations.last_7 as Duration;

type UseDataTableDurationOutput = [boolean, Duration, UpdateUserConfigFn<Duration>];

const useDataTableDuration = (
  componentId: DataTableComponentId,
  defaultState: Duration = defaultDuration
): UseDataTableDurationOutput => {
  const defaultValue = useMemo(() => ({ duration: defaultState }), [defaultState]);

  const [dataTableUserConfig, updateDataTableUserConfig, isLoading] = useUserConfig<DataTableUserConfig>(
    componentId,
    defaultValue
  );

  const duration = dataTableUserConfig?.duration || defaultState;

  const [orgKey] = useDataTableOrgKey();

  const valueExpressionContext = useValueExpressionContext();

  const { getByOrgKey } = useMetricQuery({
    orgKeys: [orgKey],
    valueExpression: valueExpressionContext.get(Touren.getSingleMetricTypeKey()),
    duration: duration,
    aggregation: DateRangeAggregationInput.Count,
  });
  const amountOfWorkDays = getByOrgKey(orgKey);

  const updateDuration = useCallback(
    (duration) => {
      updateDataTableUserConfig({
        ...dataTableUserConfig,
        duration,
      });
    },
    [dataTableUserConfig, updateDataTableUserConfig]
  );

  const durationToBeReturned = createDurationFromPlainObject({ ...duration, amountOfWorkDays });

  return [isLoading, durationToBeReturned, updateDuration];
};

export default useDataTableDuration;
