import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'returns.finished_deliveries.items.count',
  label: 'Abgeholte Sendungen',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  sumLabel: 'Abgeholte Sendungen',
  category: 'return-items',
  description: '<p>Anzahl aller abgeholten Sendungen</p>\n',
});

export default new ValueExpression(metric);
