import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'zustellzeit_netto',
  label: 'Netto-Zustellzeit',
  definition: getDefinitionByName('Netto-Zustellzeit'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
});

const expression = new ValueExpression(metric);

export default expression;
