import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'zustellzeit_netto_avg',
  label: 'Ø Netto-Zustellzeit',
  definition: getDefinitionByName('Netto-Zustellzeit'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0 h',
  valueQuotient: 3600,
  description: 'Zeit vom ersten bis zum letzten Stopp bereinigt um Pausenzeiten.',
});

const expression = new ValueExpression(metric);
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
