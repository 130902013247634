import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'boimport.abwicklungsmenge.zsb.count.total',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    definition: getDefinitionByName('Abwicklungsmenge (ZSB/VP+)'),
    label: 'Abwicklungsmenge (ZSB/VP+)',
    sumLabel: 'Abwicklungsmenge (ZSB/VP+)',
  })
);
