import MetricType, { ChartRenderOptions, mapKeyLabels } from '../../../metrics2/models/entities/MetricType';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import { PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import { AggregationType } from '../../../metrics2/models/enumerations/AggregationType';
import MetricDataUtils from '../../../metrics2/converters/MetricDataUtils';
import { CloneInterface } from '@legacy-modules/utils/CloneInterface';
import { ValueFormatter } from '@legacy-modules/valueexpressions/models/types/ValueFormatter';
import formatNumber from 'format-number-with-string';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

const stundenToMillisekundenMultiplier = 60 * 60 * 1000;

export enum TargetValueType {
  QUOTE,
  SUM,
}

export default class ValueExpression implements CloneInterface {
  identifier: string;
  shortLabel?: string;
  longLabel?: string;
  type: string;
  chartRenderOptions: ChartRenderOptions = {};
  nullValue: string = '-';
  definition: KpiDefinitionWithoutName;
  metricType: MetricType;
  label?: string;
  percent: boolean = false;
  decimalPlaces: number = 2;
  targetValueType?: TargetValueType;

  constructor(metricType: MetricType, label: string | null | undefined = null) {
    this.metricType = metricType;
    this.definition = metricType?.definition;
    this.label = label;
    this.identifier = metricType?.key;
  }

  clone(): ValueExpression {
    return new ValueExpression(this.metricType);
  }

  get key(): string {
    return `sve:${this.metricType?.key}`;
  }

  getSingleMetricTypeKey(): string {
    return this.metricType.key;
  }

  getRequiredMetricTypes(id: string = null): Array<{ type: MetricType; valueKey?: string; id: string }> {
    return [{ type: this.metricType, id: id ? id : 'default' }];
  }

  get category(): string | null | undefined {
    return this.metricType.category;
  }

  get chartType(): ChartType {
    return this.metricType.chart ?? ChartType.line;
  }

  get positiveDirection(): PositiveDirections {
    return this.metricType.positiveDirection;
  }

  get aggregation(): AggregationType {
    return this.metricType.aggregation;
  }

  getLabel(mapKey: string | null = null): string {
    if (this.label) {
      return this.label;
    }
    if (mapKey) {
      const mapKeyLabels_ = mapKeyLabels[this.metricType.key];
      if (mapKeyLabels_ && mapKeyLabels_[mapKey]) {
        return mapKeyLabels_[mapKey];
      }
      return mapKey;
    }
    return this.metricType.label;
  }

  getDescription(mapKey = null, language = null): string | null | undefined {
    if (mapKey) {
      const mapKeyLabels = this.metricType.mapKeyLabels;
      if (mapKeyLabels && mapKeyLabels[mapKey]) {
        return `Detail-Wert ${mapKeyLabels[mapKey]} von ${this.getLabel(null)}.`;
      }
    }
    return this.metricType.description;
  }

  getValueFormat(): string {
    return this.metricType.valueFormat;
  }

  processValues(metrics: Map<MetricsEntityKey, number>): Map<ValueExpressionEntityKey, number> {
    const key = this.key;
    const aggregationFn = MetricDataUtils.aggregationFunction(this.aggregation);
    const veEntityKeys: {
      [key: string]: ValueExpressionEntityKey;
    } = {};
    const mek2veek: Map<MetricsEntityKey, string> = new Map();
    Array.from(metrics.keys())
      .filter((mek: MetricsEntityKey) => mek.type === this.metricType.key)
      .map((mek: MetricsEntityKey) => {
        return {
          mek,
          veek: new ValueExpressionEntityKey(key, mek.orgKey, mek.dateFrom, mek.dateUntil, mek.grouping, mek.valueKey),
        };
      })
      .forEach(({ mek, veek }: { mek: MetricsEntityKey; veek: ValueExpressionEntityKey }) => {
        mek2veek.set(mek, veek.identifier);
        veEntityKeys[veek.identifier] = veek;
      });
    const resultMap = new Map();
    metrics.forEach((value, mek) => {
      const veek: ValueExpressionEntityKey = veEntityKeys[mek2veek.get(mek)];
      const oldValue = resultMap.get(veek) || 0.0;
      resultMap.set(veek, aggregationFn(value, oldValue));
    });
    return resultMap;
  }

  hasChildren(): boolean {
    return this.getChildren().length > 0;
  }

  getChildren(): Array<ValueExpression> {
    return [];
  }

  getShortLabel(mapKey: string | null | undefined = null): string {
    if (this.shortLabel) {
      return this.shortLabel;
    }

    return this.getLabel(mapKey);
  }

  getValueFormatter(_language?: string): ValueFormatter {
    return (value: number) => {
      const safeValue = value || 0;
      let valueFormat = this.getValueFormat();
      if (valueFormat?.endsWith('hh:mm')) {
        const time = Intl.DateTimeFormat('de-DE', {
          timeStyle: 'short',
          // use UTC here because we doesn't want to deal with timezone offsets
          // if you set German timezone, you will get 23:00 instead of 22:00 after formatting
          timeZone: 'UTC',
        }).format(Math.abs(safeValue * stundenToMillisekundenMultiplier));
        return safeValue < 0 ? '-' + time : time;
      }
      if (!valueFormat.startsWith('-')) {
        valueFormat = `-${valueFormat}`;
      }
      if (valueFormat.endsWith('h')) {
        if (Math.abs(safeValue) < 1) {
          const minutes = Math.round(safeValue * 60);
          if (Math.abs(minutes) === 60) {
            return `${minutes / 60}:00 h`;
          }
          return `${minutes} min`; // 0,5 -> "30 min" 0,49 -> "29 min" 0.51 -> "31 min"
        }
        const rest = Math.abs(safeValue) % 1;
        const hours = Math.abs(safeValue) - rest;
        const roundedMinutes = Math.round(rest * 60);
        // Im Grenzfall wollen wir bei z.B. 59 min und 31 sec auf 1 Stunde runden anstatt auf 60 Minuten
        if (roundedMinutes === 60) {
          return `${hours + 1}:00 h`;
        }
        // 7,5 -> "7:30 h" 7,49 -> "7:29 h" 7.51 -> "7:31 h"
        return `${safeValue >= 0 ? hours : -hours}:${roundedMinutes > 9 ? roundedMinutes : '0' + roundedMinutes} h`;
      }
      return formatNumber(safeValue, valueFormat);
    };
  }

  getTargetValueType(): TargetValueType {
    return this.targetValueType ? this.targetValueType : TargetValueType.SUM;
  }
}
