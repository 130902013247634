import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'returns.finished_deliveries.count',
  label: 'Abholvorgänge',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'returns',
  description:
    '<p>Anzahl aller abgeschlossenen Abholvorgänge, bei der ein Abholvorgang die Bearbeitung eines oder ' +
    'mehrerer Pakete für ein oder mehrere Kunden ist</p>\n',
});

export default new ValueExpression(metric);
