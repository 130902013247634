import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Benachrichtigungen nach Art';
const key = 'finished_deliveries.notifications.by_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${label} (${category.label})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'deliveries',
    description: '<p>Anzahl aller auf Tour angefallenen Benachrichtigungen nach Art (BNK, Email)</p>\n',
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;
sumType.chart = ChartType.line;

const sumExpression = new ValueExpression(sumType);

const categories = {
  card: 'BNK',
  email: 'Email',
};

sumExpression.getChildren = () =>
  Object.entries(categories).map((category) => {
    const [key, label] = category;
    return new ValueExpression(getMetricType({ key: key, label: label }));
  });

export default sumExpression;
