import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'sat.deliveryitems.intake.forecast.amount',
  label: 'Prognostizierte Sendungseingangsmenge',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'sat',
  description: '<p>Prognostizierte Sendungseingangsmenge</p>\n',
  hidden: true,
  definition: getDefinitionByName('Prognostizierte Sendungseingangsmenge'),
});

const expression = new ValueExpression(metric);

export default expression;
