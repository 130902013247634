import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression, {
  TargetValueType,
} from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';

const expression = new ValueExpression(
  new MetricType({
    key: 'planned_stops.order_compliance.quote',
    aggregation: AggregationType.none,
    positiveDirection: PositiveDirection.up,
    label: 'RF-Einhaltung',
    definition: getDefinitionByName('RF-Einhaltung'),
    chart: ChartType.bar,
    valueFormat: '-#.###.##0,#0%',
    valueQuotient: 0.01,
  })
);
expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;
expression.longLabel = 'Reihenfolgeeinhaltung';

export default expression;
