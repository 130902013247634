import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const label = 'Zustellungen nach Art';
const key = 'lma-shipmentbased.ZU.all.count.by_delivery_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${category.label}`,
    sumLabel: 'Zustellungen',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    chart: ChartType.bar,
    description: '<p>Anzahl der erfolgreich bearbeiteten Sendungen nach Zustellart</p>\n',
    category: 'delivery-items',
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;

const sumExpression = new ValueExpression(sumType);

export const deliveryTypes = {
  props: 'ProPs',
  paketShop: 'PaketShop',
  person: 'unbekannt',
  'person:atDoor': 'In Wohnung',
  'person:intendedReceiver': 'Originalempfänger',
  'person:nextDoor': 'Nachbar',
  'person:reception': 'Rezeption',
  'person:staff': 'Mitarbeiter',
  'putDownOutside:Bag': 'Zustelltüte',
  'putDownOutside:bnkAuthorization': 'BNK-Vollmacht',
  'putDownOutside:Mailbox': 'Briefkasten',
  'putDownOutside:oneTimeAuthorization': 'Einmalvollmacht',
  'putDownOutside:permanentAuthorization': 'Dauervollmacht',
  'putDownOutside:unknownAuthorization': 'Vollmacht',
};

sumExpression.getChildren = () =>
  Object.entries(deliveryTypes).map((type) => {
    const [key, label] = type;
    return new ValueExpression(getMetricType({ key: key, label: label }));
  });

export default sumExpression;
