import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const label = 'PS Mitnahmen nach Rückgabegrund';
const key = 'lma-shipmentbased.PSE.all.count.by_return_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category}`,
    label: `${label} (${category})`,
    aggregation: AggregationType.sum,
    hidden: true,
    positiveDirection: PositiveDirection.custom,
  });
};

const sumType = getMetricType('sum');
sumType.label = label;
sumType.chart = ChartType.line;

const psMitnahmenNachRueckgabegrundSum = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
const categories = ['Gepäck', 'PriPS', 'Retoure'];
psMitnahmenNachRueckgabegrundSum.getChildren = () =>
  categories.map((category) => new ValueExpression(getMetricType(category)));

export default psMitnahmenNachRueckgabegrundSum;
