import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'stock.stored_per_inventory',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.down,
  label: 'Lagernd-Quote',
  definition: getDefinitionByName('Lagernd-Quote'),
  chart: ChartType.bar,
  category: 'stock',
  valueFormat: '-#.###.##0,#0%',
  valueQuotient: 0.01,
});

const expression = new ValueExpression(metric);
expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
