import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new ValueExpression(
  new MetricType({
    key: 'abholungen',
    label: 'Abholungen ohne Mitnahmen',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
    definition: getDefinitionByName('Abholungen ohne Mitnahmen'),
    hidden: true,
  })
);

export default expression;
