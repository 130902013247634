import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'finished_deliveries.items.delivered.inkasso_amount',
  label: 'Kassierter Geldbetrag',
  aggregation: AggregationType.sum,
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0, Cent',
  category: 'deliveries',
  description: '<p>Euro-Summe aller erfolgreich zugestellten Sendungen mit Nachnahmebetrag</p>\n',
});

export default new ValueExpression(metric);
