import MetricType, { abhMitReturnTypes } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Mitnahmen nach Typ';
const key = 'lma-shipmentbased.MIT.all.count.by_return_type';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category.key}`,
    label: `${category.label}`,
    sumLabel: 'Mitnahmen',
    aggregation: AggregationType.sum,
    hidden: true,
    positiveDirection: PositiveDirection.custom,
  });
};

const sumType = getMetricType({ key: 'sum', label: '' });
sumType.label = label;

const sumExpression = new ValueExpression(sumType);

sumExpression.getChildren = () =>
  abhMitReturnTypes.map((reason) => {
    return new ValueExpression(getMetricType({ key: reason, label: reason }));
  });

export default sumExpression;
