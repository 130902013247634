import { useCallback, useMemo } from 'react';

import { LoadingState, OrgKey, ValueExpressionIdentifier } from '@data-table/data-table.types';
import { Duration, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { DurationUtils } from '@utils/duration-utils';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';

type UseDataTableValueExpressionDataOutput = [
  Map<ValueExpressionIdentifier, Map<OrgKey, number>>,
  (valueExpressionIdentifier: ValueExpressionIdentifier, filter: OrgKey[]) => number,
  (valueExpressionIdentifier: ValueExpressionIdentifier) => number,
  LoadingState
];
const useDataTableValueExpressionData = (
  currentOrgKey: OrgKey,
  orgKeys: OrgKey[],
  valueExpressions: ValueExpression[],
  duration: Duration,
  isComparison: boolean = false,
  enabled: boolean = true
): UseDataTableValueExpressionDataOutput => {
  const [weekdayFilter] = useMemo(() => {
    const filterObject = duration.weekdayFilter || defaultWeekdaysFilter;
    return [
      filterObject,
      Object.keys(filterObject)
        .filter((key) => filterObject[key])
        .join(','),
    ];
  }, [duration.weekdayFilter]);

  const primaryRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { data, isLoading } = useKpiQuery(
    {
      orgKeys: [currentOrgKey, ...orgKeys],
      dateRange: primaryRange,
      valueExpressions: valueExpressions as ValueExpression[],
      weekdayFilter,
    },
    {
      enabled:
        orgKeys?.length > 0 && valueExpressions?.length > 0 && !!primaryRange.from && !!primaryRange.to && enabled,
    }
  );

  const orgKeyMap = useMemo(() => {
    const dataMap = new Map<string, Map<OrgKey, number>>();
    data?.kpis?.groups?.forEach((result) => {
      result.kpiValues.forEach((kpiValue) => {
        if (dataMap.has(kpiValue.kpiId)) {
          const outerMap = dataMap.get(kpiValue.kpiId);
          outerMap.set(result.orgKey, kpiValue.value);
        } else {
          dataMap.set(kpiValue.kpiId, new Map([[result.orgKey, kpiValue.value]]));
        }
      });
    });
    return dataMap;
  }, [data?.kpis?.groups]);

  // TODO: This is a dummy callback for now. Can be removed once filtering via request is implemented.
  const getFilteredSumm = useCallback((valueExpressionIdentifier: ValueExpressionIdentifier, filter: OrgKey[]) => {
    return 0;
  }, []);

  const getRootOrgSumm = useCallback(
    (valueExpressionIdentifier: ValueExpressionIdentifier): number => {
      const valueExpression = valueExpressions.find(
        (valueExpression) => valueExpression.identifier === valueExpressionIdentifier
      );
      if (!valueExpression) {
        return 0;
      }
      const value = data?.kpis?.groups
        ?.find((result) => result.orgKey === currentOrgKey)
        ?.kpiValues?.find((kpiValue) => {
          return kpiValue.kpiId === valueExpressionIdentifier;
        })?.value;
      return value || 0;
    },
    [data?.kpis?.groups, currentOrgKey, valueExpressions]
  );
  return [orgKeyMap, getFilteredSumm, getRootOrgSumm, isLoading];
};

export default useDataTableValueExpressionData;
