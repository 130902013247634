import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'stock.inventory',
    label: 'Bestand',
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: 'down',
    category: 'stock',
    description: '<p>Description for stock.inventory - tbd</p>\n',
    definition: getDefinitionByName('Bestand'),
  })
);
