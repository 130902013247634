import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'GPS Genauigkeit';
const key = 'finished_deliveries.stop_journey.location.accuracy';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category}`,
    label: `${label} (${category})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'tours',
    description: '<p>GPS Genauigkeit in Metern, gruppiert</p>\n',
  });
};

const sumType = getMetricType('sum');
sumType.label = label;
sumType.chart = ChartType.line;

const gpsLocationAccuracySum = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
const categories = ['0-5', '6-10', '11-20', '21-50', '51-100', '101-200', '>200'];
gpsLocationAccuracySum.getChildren = () => categories.map((category) => new ValueExpression(getMetricType(category)));

export default gpsLocationAccuracySum;
