import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'tour_loaded.deliveryitems.services.nachnahme.sum',
  label: 'Nachnahmebetrag',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0, Cent',
  category: 'delivery-items',
  description:
    '<p>Summe der Nachnahmebeträge aller auf Tour geladenen Sendungen mit Nachnahmezustellung gezählt nach ' +
    'dem ersten abgeschlossenen Zustellvorgang</p>\n',
});

export default new ValueExpression(metric);
