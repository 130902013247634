import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'lma-shipmentbased.TA.valuable.count.total',
  aggregation: AggregationType.sum,
  hidden: true,
  positiveDirection: PositiveDirection.custom,
  label: 'Wertsendungen auf Tour inkl. nicht gestartet',
  definition: getDefinitionByName('Wertsendungen auf Tour'),
});

export default new ValueExpression(metric);
