import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'ist_lademenge/touren',
  label: 'ø IST-Lademenge',
  definition: getDefinitionByName('ø IST-Lademenge'),
  positiveDirection: PositiveDirection.custom,
  valueFormat: '-#.###.##0,0',
});

const expression = new ValueExpression(metric);

expression.targetValueType = TargetValueType.QUOTE;
expression.percent = false;

export default expression;
