import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

export default new ValueExpression(
  new MetricType({
    key: 'lma-anomalies.wzf.atg-abholung.count',
    label: 'Außerhalb ATG-AZF',
    sumLabel: 'Außerhalb ATG-AZF',
    aggregation: AggregationType.sum,
    positiveDirection: PositiveDirection.custom,
  })
);
