import React from 'react';
import { ColumnFilterContentProps, ValueExpressionDataTableColumnMeta } from '@data-table/data-table.types';
import { NumberValueExpressionFilterContent } from './number-value-expression-filter-content';
import { PercentBasedValueExpressionFilterContent } from './percent-based-value-expression-filter-content';
import { QuotientenValueExpressionFilterContent } from './quotienten-value-expression-filter-content';
import { TimeBasedValueExpressionFilterContent } from './time-based-value-expression-filter-content';

const ValueExpressionFilterContent = <T, V>({ column, ...rest }: ColumnFilterContentProps<T, V>) => {
  const valueExpression = (column?.columnDef?.meta as ValueExpressionDataTableColumnMeta)?.valueExpression;
  const isTimeBasedValueExpression = (!!valueExpression && valueExpression?.getValueFormat()?.endsWith(' h')) ?? false;
  const isPercentBasedValueExpression =
    (!!valueExpression && valueExpression?.getValueFormat()?.endsWith('%')) ?? false;
  const isQuotientenValueExpression =
    (!!valueExpression && !isPercentBasedValueExpression && !isTimeBasedValueExpression) ?? false;

  if (isTimeBasedValueExpression) {
    return <TimeBasedValueExpressionFilterContent<T, V> column={column} {...rest} />;
  } else if (isPercentBasedValueExpression) {
    return (
      <PercentBasedValueExpressionFilterContent<T, V>
        column={column}
        decimalPlaces={valueExpression?.decimalPlaces}
        {...rest}
      />
    );
  } else if (isQuotientenValueExpression) {
    return (
      <QuotientenValueExpressionFilterContent<T, V>
        column={column}
        decimalPlaces={valueExpression?.decimalPlaces}
        {...rest}
      />
    );
  }
  return <NumberValueExpressionFilterContent<T, V> column={column} {...rest} />;
};

export default ValueExpressionFilterContent;
