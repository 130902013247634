import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Styles from './KpiOverviewBox.module.scss';
import IconButton from '../../common/components/IconButton';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { ContextMenuItem } from '../../common/components/ContextMenuItem';
import { ContextMenuList } from '../../common/components/ContextMenuList';
import { PopOver } from '../../common/components/PopOver';
import { useValueExpression } from '../hooks/useValueExpression';
import { useSelector } from 'react-redux';
import { Duration } from '../models/enums/Duration';
import { KpiCompareValue } from './KpiCompareValue';
import ReactPlaceholder from 'react-placeholder';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import KpiValue from './KpiValue';
import KpiSelectorOverlay from '../kpiCompare/KpiSelectorOverlay';
import { KpiOverviewContext } from './KpiOverview';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { getKpiDiffValueTitle } from '../charts/DetailedChartKpiValue';
import { useRanges } from '../hooks/useRanges';
import { ValueExpressionName } from '../../common/components/ValueExpressionName';
import { Tooltip } from '../../common/components/Tooltip';
import { KpiCustomThresholdSelection } from './KpiCustomThresholdSelection/KpiCustomThresholdSelection';
import { useKpiIndicator } from '../hooks/useKpiIndicator';
import KpiIndicatorHover from './KpiIndicatorHover';
import { selectDashboardOrgKey } from '@redux/dashboard.selectors';
import { useAuthContext } from '@contexts/auth-context';
import { useOrganizationData } from '@hooks/use-organization-data-hook';
import { useOrgConfig } from '@hooks/use-org-config-hook';
import { reviveGoalClass } from '@hooks/use-org-config-hook/use-org-config.hook';
import { DurationUtils } from '@utils/duration-utils';

type Props = {
  className?: string;
  variation?: 'small' | 'large';
  onRemove: () => void;
  onLimitChange: (positive: number, negative: number) => void;
  onKpiChange: (newIdentifier: string) => void;
  duration: Duration;
  valueExpression: ValueExpression;
  selectedValueExpressions: ValueExpression[];
  onBoxSelection?: () => void;
  selectable: boolean;
  workdaysInRange: number;
};

const TextLoader = ({ children, ready }: { children: React.ReactNode; ready: boolean }) => (
  <ReactPlaceholder ready={ready} rows={1} type={'text'} showLoadingAnimation={true}>
    {children}
  </ReactPlaceholder>
);

const KpiOverviewBox: React.FC<Props> = ({
  className,
  variation = 'large',
  onRemove,
  onLimitChange,
  onKpiChange,
  duration,
  valueExpression,
  selectedValueExpressions,
  onBoxSelection,
  selectable,
  workdaysInRange,
}) => {
  const orgKey = useSelector(selectDashboardOrgKey);
  const [thresholdPopupOpen, setThresholdPopupOpen] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [kpiPopupOpen, setKpiPopupOpen] = useState(false);
  const [indicatorHovering, setIndicatorHovering] = useState(false);
  const indicatorRef = useRef<HTMLSpanElement>();
  const { setDraggingDisabled } = useContext(KpiOverviewContext);

  useEffect(() => {
    const popOvers = [thresholdPopupOpen, contextMenuVisible, kpiPopupOpen];
    if (popOvers.some((v) => v)) {
      setDraggingDisabled(true);
    } else {
      setDraggingDisabled(false);
    }
  }, [contextMenuVisible, kpiPopupOpen, setDraggingDisabled, thresholdPopupOpen]);

  const dateRange = useMemo(() => {
    return DurationUtils.getDateRange(duration);
  }, [duration]);
  const compareRange = useMemo(() => {
    return DurationUtils.getComparisonDateRange(duration);
  }, [duration]);

  const primary = useValueExpression(valueExpression, orgKey, dateRange, duration.weekdayFilter);
  const compare = useValueExpression(valueExpression, orgKey, compareRange, duration.weekdayFilter);

  const { canAccessStop } = useAuthContext();
  const selectedDashboardOrgKey = useSelector(selectDashboardOrgKey);
  const [orgUnit] = useOrganizationData([selectedDashboardOrgKey], ['breadcrumb']);
  const allowedToEditOrgGoals = canAccessStop(orgUnit?.get(selectedDashboardOrgKey)?.breadcrumb);

  const isLoading = primary.loading || compare.loading;

  const { data: orgConfig } = useOrgConfig(orgKey, valueExpression.identifier);

  const zielwert = useMemo(() => {
    return reviveGoalClass(
      orgConfig?.values?.find((config) => config.orgKey === orgKey && config.configKey === valueExpression?.identifier)
        ?.value
    );
  }, [orgConfig, valueExpression, orgKey]);

  const { color: kpiIndicatorColor } = useKpiIndicator(
    zielwert,
    valueExpression,
    isLoading,
    primary.data,
    workdaysInRange
  );

  const selectedIdentifier = useMemo(() => {
    return selectedValueExpressions.map((s) => s.identifier);
  }, [selectedValueExpressions]);

  const onKpiCloseCallback = useCallback(() => {
    setKpiPopupOpen(false);
  }, []);

  const kpiOverlay = (
    <PopOver anchorElement={referenceRef} placement={'right-start'} visible={kpiPopupOpen} onClose={onKpiCloseCallback}>
      <KpiSelectorOverlay selected={selectedIdentifier} onSelect={onKpiChange} />
    </PopOver>
  );

  const onKpiThresholdItemSelectionCallback = useCallback(() => {
    setContextMenuVisible(false);
    setThresholdPopupOpen(true);
  }, []);

  const onKpiChangeItemSelectionCallback = useCallback(() => {
    setKpiPopupOpen(true);
    setContextMenuVisible(false);
  }, []);

  const onRemoveKpiItemSelectionCallback = useCallback(() => {
    onRemove();
  }, [onRemove]);

  const contextMenu = (
    <ContextMenuList>
      {allowedToEditOrgGoals && (
        <AuthGuard requiredPermissions={[WellKnownPermission.DashboardSetGoals]}>
          <ContextMenuItem onClick={onKpiThresholdItemSelectionCallback}>Zielwerte anpassen</ContextMenuItem>
        </AuthGuard>
      )}
      <ContextMenuItem onClick={onKpiChangeItemSelectionCallback}>Kennzahl ändern</ContextMenuItem>
      <ContextMenuItem onClick={onRemoveKpiItemSelectionCallback}>Kennzahl entfernen</ContextMenuItem>
    </ContextMenuList>
  );

  const { compareRange: compareDateRange } = useRanges(duration);

  const hoverText = getKpiDiffValueTitle(compareDateRange, valueExpression);

  const onClickCallback = useCallback(
    (e) =>
      selectable &&
      onBoxSelection &&
      // avoid selection clicks on context menu
      !referenceRef?.contains(e?.target) &&
      onBoxSelection(),
    [onBoxSelection, referenceRef, selectable]
  );

  const onMouseLeaveCallback = useCallback(() => {
    if (contextMenuVisible || thresholdPopupOpen || kpiPopupOpen) {
      return;
    }
    setContextMenuVisible(false);
    setThresholdPopupOpen(false);
  }, [contextMenuVisible, kpiPopupOpen, thresholdPopupOpen]);

  const onIconButtonClickCallback = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuVisible(true);
  }, []);

  const onPopOverCloseCallback = useCallback(() => {
    setContextMenuVisible(false);
  }, []);

  const onKpiValueSelectedCallback = useCallback(
    (positive, negative) => {
      setThresholdPopupOpen(false);
      onLimitChange(positive, negative);
    },
    [onLimitChange]
  );

  const onIndicatorHoverCallback = useCallback(() => {
    setIndicatorHovering(true);
  }, []);

  const onIndicatorLeaveCallback = useCallback(() => {
    setIndicatorHovering(false);
  }, []);

  const onKpiThresholdSelectionCloseCallback = useCallback(() => {
    setThresholdPopupOpen(false);
  }, []);

  return (
    <div
      onClick={onClickCallback}
      className={classNames(Styles.KpiOverviewBox, className, {
        [Styles.Selectable]: selectable,
        [Styles.small]: variation === 'small',
      })}
      onMouseLeave={onMouseLeaveCallback}>
      <div ref={setReferenceRef} className={Styles.ButtonWrapper}>
        <IconButton className={Styles.MenuButton} onClick={onIconButtonClickCallback} icon={<BsThreeDotsVertical />} />
        <PopOver
          anchorElement={referenceRef}
          placement={'right-start'}
          onClose={onPopOverCloseCallback}
          visible={contextMenuVisible}>
          {contextMenu}
        </PopOver>
        {kpiOverlay}
        <PopOver
          anchorElement={referenceRef}
          placement={'right-start'}
          onClose={onKpiThresholdSelectionCloseCallback}
          visible={thresholdPopupOpen}>
          <KpiCustomThresholdSelection
            valueExpression={valueExpression}
            onValuesSelected={onKpiValueSelectedCallback}
            workdaysInRange={workdaysInRange}
            veData={primary.data}
            zielwert={zielwert}
            onClose={() => setThresholdPopupOpen(false)}
          />
        </PopOver>
      </div>
      <div className={Styles.Wrapper}>
        <span
          ref={indicatorRef}
          onMouseOver={onIndicatorHoverCallback}
          onMouseOut={onIndicatorLeaveCallback}
          className={classNames(Styles.Indicator, Styles[kpiIndicatorColor], {
            [Styles.small]: variation === 'small',
          })}
        />
        <Tooltip anchorElement={indicatorRef?.current} visible={indicatorHovering} placement={'left'}>
          <div className={Styles.IndicatorOverlay}>
            <KpiIndicatorHover
              zielwert={zielwert}
              primaryData={primary.data}
              valueExpression={valueExpression}
              workdaysInRange={workdaysInRange}
            />
          </div>
        </Tooltip>
        <div className={Styles.KpiSection}>
          <ValueExpressionName
            className={Styles.KpiName}
            valueExpression={valueExpression}
            helpToolTipPlacement={'top'}
            labelName={'short'}
          />
          <TextLoader ready={!primary.loading}>
            <KpiValue className={Styles.KpiValue} valueExpression={valueExpression} veData={primary.data} />
          </TextLoader>
          <React.Fragment key={'sec' + variation + valueExpression.identifier}>
            {variation === 'large' && (
              <TextLoader ready={!isLoading} key={'compareValue' + valueExpression.identifier}>
                <div>
                  <KpiCompareValue
                    primaryVE={valueExpression}
                    primaryData={primary.data}
                    compareData={compare.data}
                    hoverText={hoverText}
                    key={valueExpression.identifier}
                    classNames={{
                      default: Styles.KpiCompareValue,
                    }}
                    zielwert={zielwert}
                  />
                </div>
              </TextLoader>
            )}
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default KpiOverviewBox;
