import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'abholungen_mitnahmen',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.custom,
  label: 'Abholungen',
  valueFormat: '-#.###.##0',
  description: 'Abholungen und Mitnahmen',
  definition: getDefinitionByName('Abholungen'),
});

const expression = new ValueExpression(metric);
expression.targetValueType = TargetValueType.SUM;

export default expression;
