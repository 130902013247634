import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'regression-gutschriften/abwicklungsmenge',
  label: 'S&V-Quote (ZSB/VP+)',
  valueFormat: '-#.###.##0,##0%',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.down,
  category: 'tours',
  definition: getDefinitionByName('S&V-Quote (ZSB/VP+)'),
  hidden: true,
  valueQuotient: 0.01,
});

const expression = new ValueExpression(metric);

expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;

export default expression;
