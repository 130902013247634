import { useCallback, useMemo } from 'react';
import moment from 'moment';

import { OrgKey } from '@data-table/data-table.types';
import { Duration, defaultWeekdaysFilter } from '@legacy-modules/dashboard/models/enums/Duration';
import MetricsEntityKey from '@legacy-modules/metrics2/models/entities/MetricsEntityKey';
import {
  DateRangeGrouping,
  DateRangeGrouping as LegacyDateRangeGrouping,
} from '@legacy-modules/metrics2/models/enumerations/DateRangeGrouping';
import { DurationUtils } from '@utils/duration-utils';
import { useKpiQuery } from '@hooks/use-kpi-query-hook';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import ValueExpressionEntityKey from '@legacy-modules/valueexpressions/models/entities/ValueExpressionEntityKey';
import { DateRangeAggregationInput } from '@graphql-client/graphql';

export type UseMetricQueryParameters = {
  orgKeys: OrgKey[];
  valueExpression: ValueExpression;
  duration: Duration;
  legacyDateRangeGrouping?: LegacyDateRangeGrouping;
  isComparison?: boolean;
  enabled?: boolean;
  aggregation?: DateRangeAggregationInput;
};

export type UseMetricQueryOutput = {
  valueMap: Map<ValueExpressionEntityKey, number>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  getByOrgKey: (orgKey: OrgKey, dateIdentifier?: string) => number;
};

export default function useMetricQuery(parameters: UseMetricQueryParameters): UseMetricQueryOutput {
  const {
    orgKeys,
    valueExpression,
    duration,
    legacyDateRangeGrouping = DateRangeGrouping.none,
    isComparison = false,
    enabled = true,
    aggregation = DateRangeAggregationInput.Sum,
  } = parameters;

  const [weekdayFilter] = useMemo(() => {
    const filterObject = duration.weekdayFilter || defaultWeekdaysFilter;
    return [
      filterObject,
      Object.keys(filterObject)
        .filter((key) => filterObject[key])
        .join(','),
    ];
  }, [duration.weekdayFilter]);

  const dateRange = isComparison
    ? DurationUtils.getComparisonDateRange(duration)
    : DurationUtils.getDateRange(duration);

  const { data, isLoading, isError, isSuccess } = useKpiQuery(
    {
      orgKeys,
      dateRange,
      valueExpressions: [valueExpression],
      grouping: legacyDateRangeGrouping,
      weekdayFilter,
      aggregation: aggregation,
    },
    {
      enabled: orgKeys?.length > 0 && !!valueExpression && !!dateRange.from && !!dateRange.to && enabled,
    }
  );
  const valueMap = useMemo(() => {
    if (!valueExpression || isLoading || !data) {
      return new Map();
    }

    const metricsEntityMap = new Map(
      data?.kpis.groups.flatMap((group) => {
        const valueKey = valueExpression
          ?.getRequiredMetricTypes()
          ?.find((type) => type.type.key === group?.kpiValues[0]?.kpiId)?.valueKey;
        // If dateRangeGrouping is set, we need to add the value to the map twice,
        // once for the date range group and once for the sum entry.
        // In case of none value this values are the same
        const type = group.kpiValues[0]?.kpiId;
        const orgKey = group.orgKey;
        const value = group.kpiValues[0]?.value;
        return legacyDateRangeGrouping === DateRangeGrouping.none
          ? [
              [
                new MetricsEntityKey(
                  type,
                  orgKey,
                  dateRange.from,
                  dateRange.to,
                  legacyDateRangeGrouping,
                  valueKey,
                  duration.weekdayFilter
                ),
                value,
              ],
            ]
          : [
              [
                new MetricsEntityKey(
                  type,
                  orgKey,
                  moment(group.dateRange.from),
                  moment(group.dateRange.until),
                  legacyDateRangeGrouping,
                  valueKey,
                  duration.weekdayFilter
                ),
                value,
              ],
              [
                new MetricsEntityKey(
                  type,
                  orgKey,
                  dateRange.from,
                  dateRange.to,
                  LegacyDateRangeGrouping.none,
                  valueKey,
                  duration.weekdayFilter
                ),
                value,
              ],
            ];
      })
    );
    const valuesMap = valueExpression.processValues(metricsEntityMap);
    return valuesMap;
  }, [data, valueExpression, legacyDateRangeGrouping, duration, dateRange, isLoading]);

  const getByOrgKey = useCallback(
    (orgKey: OrgKey, dateIdentifier?: string) => {
      if (!orgKey || valueMap?.size <= 0) {
        return undefined;
      }
      return Array.from(valueMap.entries())
        .find(([valueExpressionEntityKey]) => {
          if (!dateIdentifier) {
            return valueExpressionEntityKey?.orgKey === orgKey;
          }
          return (
            valueExpressionEntityKey?.orgKey === orgKey && valueExpressionEntityKey?.dateIdentifier === dateIdentifier
          );
        })
        ?.at(1);
    },
    [valueMap]
  );

  return {
    valueMap,
    isLoading,
    isError,
    isSuccess,
    getByOrgKey,
  };
}
