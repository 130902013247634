import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const label = 'Zugestellte Sendungen nach Uhrzeit';
const key = 'finished_deliveries.items.delivered.by_hour';

const getMetricType = (category) => {
  return new MetricType({
    key: `${key}.${category}`,
    label: `${label} (${category})`,
    aggregation: AggregationType.sum,
    chart: ChartType.bar,
    positiveDirection: PositiveDirection.custom,
    category: 'delivery-items',
    description: '<p>Anzahl erfolgreich zugestellter Sendungen (kein Rücklauf) nach Uhrzeit</p>\n',
  });
};

const sumType = getMetricType('sum');
sumType.label = label;
sumType.chart = ChartType.line;

const finishedDeliveriesItemsDeliveredByHourSum = new ValueExpression(sumType);

// hacky solution for "Übersichtsbereich"
const categories = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
finishedDeliveriesItemsDeliveredByHourSum.getChildren = () =>
  categories.map((category) => new ValueExpression(getMetricType(category)));

export default finishedDeliveriesItemsDeliveredByHourSum;
