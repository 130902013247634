import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import { TargetValueType } from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';

const metric = new MetricType({
  key: 'einwohner_pro_kilometer2',
  label: 'Einwohner pro km²',
  valueFormat: '-#.###.##0,0',
});

const expression = new ValueExpression(metric);

expression.percent = true;
expression.targetValueType = TargetValueType.QUOTE;
expression.chartRenderOptions.startYAxisOnZero = false;

export default expression;
